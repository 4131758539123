// import { FC } from "react";
import { useState } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import MobileNavbar from './MobileNavbar';
import helpailogo from '../../assets/images/helpailogo.svg';
import '../../assets/css/Navbar.css';
import ChevronDownOutline from '../../assets/images/ChevronDownOutline.svg';
import menuSvg from '../../assets/images/Vector Hamburger Menu.svg';
import closeSvg from '../../assets/images/Vector Close.svg';
import NavButton from '../../components/navbutton/NavButton';

const Navbar = () => {
	const navigate = useNavigate();
	const [showMobileNav, setShowMobileNav] = useState(true);

	const gotoCreateAccount = () => {
		navigate('/create-account');
	};

	return (
		<>
			<div className='relative z-20 nav-no-bg pl-5 pr-12 pt-8 pb-5 bg-white changa'>
				<div className='container mx-auto max-w-screen-2xl'>
					<div className='flex justify-between items-center'>
						{/* 1 */}
						<Link to='/'>
							<div className='icon-big w-32 h-14'>
								{/* optimize this image either by lazyloadding or ...*/}
								<img
									src={helpailogo}
									alt='Helpai Logo'
								/>
							</div>
						</Link>
						{/* 2 */}
						<nav className='hidden show-nav text-lg font-medium'>
							<ul className='flex gap-[80px] items-center'>
								<ScrollLink
									to='top'
									smooth={true}
									duration={900}>
									<li className='cursor-pointer leading-[33px] hover:text-[#414080]'>Home</li>
								</ScrollLink>
								<ScrollLink
									to='user-guide-section'
									smooth={true}
									duration={1500}
									href='#user-guide-section'>
									<li className='cursor-pointer leading-[33px] hover:text-[#414080]'>User Guide</li>
								</ScrollLink>
								<ScrollLink
									to='pricing'
									smooth={true}
									duration={2000}>
									<li className='cursor-pointer leading-[33px] hover:text-[#414080]'>Pricing</li>
								</ScrollLink>
							</ul>
						</nav>
						{/* 3 */}
						<div className='hidden show-nav-login items-center gap-[31px] font-medium leading-[33px]'>
							<div>
								<Link to='/login'>
									<p className='text-lg leading-[33px] hover:text-[#1A1A33]'>Log In</p>
								</Link>
							</div>
							<div>
								<NavButton onClick={gotoCreateAccount}>Create Account</NavButton>
							</div>
						</div>

						<div className='hide-nav'>
							<button onClick={() => setShowMobileNav((prevVal) => !prevVal)}>
								<img
									src={showMobileNav ? menuSvg : closeSvg}
									alt={showMobileNav ? 'Hamburger Menu' : 'Close Icon'}
								/>
							</button>
						</div>
					</div>
				</div>
			</div>

			{/* Mobiile Menu */}
			<MobileNavbar showMobileNav={showMobileNav} />
		</>
	);
};

export default Navbar;
