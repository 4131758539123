export const industryOptions = [
  'Fintech',
  'Agriculture',
  'EdTech',
  'Gaming',
  'VR',
  'Healthcare',
  'E-commerce',
  'SaaS',
  'Entertainment',
  'Automotive',
  'Real Estate',
  'Other...',
];
