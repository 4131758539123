import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';

import Footer from '../../layout/footer/Footer';
import logo from '../../assets/images/helpailogo.svg';
import logomobile from '../../assets/images/logomobile.svg';
import BASE_URL from '../../services/index.js';

const ExpiredVerificationLink = () => {
  const navigate = useNavigate();
  const storedEmail = localStorage.getItem('userEmail');
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isResending, setIsResending] = useState(false);

  // console.log(storedEmail)

  const handleSignIn = () => {
    navigate('/login');
  };

  const handleResendClick = async () => {
    if (storedEmail && !isResending) { 
      setIsResending(true); 
      try {
        const response = await axios.post(BASE_URL + `/auth/resend-verification`, {
          email: storedEmail
        });
				console.log(email);

        if (response.data) {
          setSuccessMessage('Verification link resent successfully.');
          setTimeout(() => {
            setSuccessMessage(null);
            navigate("/login");
          }, 7500);
        } else {
          setErrorMessage('An error occurred. Please try again.');
          // console.log(error)
          setTimeout(() => {
            setErrorMessage(null);
          }, 7500);
        }
      } catch (error) {
        setErrorMessage('An error occurred. Please try again.');
				console.log(error)
        setTimeout(() => {
          setErrorMessage(null);
        }, 7500);
      } finally {
        setIsResending(false);
      }
    }
  };

  return (
    <>
      <div className='bg-[#FAFBFD] px-10 py-5 h-[650px] changa'>
        <div className='mb-10'>
          <img
            src={logo}
            srcSet={logomobile}
            alt='Description of the image'
          />
        </div>
        <div className='flex flex-col justify-center text-center'>
          <h1 className='text-[#454488] text-3xl md:text-6xl changa-bold mb-5'>
            The link you followed has expired
          </h1>
          <p className='text-base md:text-xl'>
            It seems your verification link has expired. To continue, please sign in to your account
          </p>
        </div>
        <div className='flex justify-center'>
          <div className='mt-16'>
            <div className='mb-6'>
              <button
                onClick={handleResendClick}
                className='w-[247px] px-5 py-3 mb-4 md:mb-0 bg-[#414080] rounded-lg text-white cursor-pointer'
              >
                Resend Verification Link
              </button>
            </div>
            <div>
              <button
                onClick={handleSignIn}
                className='px-10 py-3 bg-[#fff] rounded-lg text-black border border-gray-300 cursor-pointer w-[247px]'>
                Sign in
              </button>
            </div>
          </div>
        </div>
        <AnimatePresence>
          {errorMessage && (
            <motion.div
              initial={{ opacity: 0, x: '100%' }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: '100%' }}
              transition={{ duration: 0.3 }}
              className='text-white md:text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg'>
              <p>{errorMessage}</p>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {successMessage && (
            <motion.div
              initial={{ opacity: 0, x: '100%' }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: '100%' }}
              transition={{ duration: 0.3 }}
              className='text-white md:text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg'>
              <p>{successMessage}</p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <Footer />
    </>
  );
};


export default ExpiredVerificationLink;