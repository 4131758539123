import PropTypes from 'prop-types';
import Footer from '../../layout/footer/Footer';

export const Layout = ({ children }) => {
	return (
		<div className='changa'>
			<main>{children}</main>
			<Footer />
		</div>
	);
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};