import React from "react";
import clsx from "clsx";

import "../../assets/css/Landingpage.css";
import res from "../../assets/images/responsibility.svg";
import skill from "../../assets/images/skill.svg";
import launch from "../../assets/images/launch.svg";
import risk from "../../assets/images/risk.svg";

const Thirdsection = () => {
  return (
    <div className="flex justify-center mb-[113px] lg:mb-[165.65px]">
      <div className="flex flex-col items-center gap-[54px] lg:gap-[107px] lg:flex-row">
        <div>
          <div className="lg:max-w-[530px]">
            <h3 className="text-[#414080] changa-semibold text-[24px] leading-[59px] mb-[14px] truncate break-words lg:text-[32px]">
              Why have a co-founder?
            </h3>
            <p className="text-lg leading-[196.5%]">
              It is crucial to have a co-founder since it enables you to split
              the workload and responsibility of starting and operating a
              business. A co-founder can provide various abilities and
              viewpoints that can help guarantee a company's success.
              <br />
              <br />
              In difficult circumstances, they can also offer moral support,
              which is quite helpful. Having a co-founder can also help ensure
              that you have a supporter who can assist you in making crucial
              decisions and offer insightful feedback.
            </p>
          </div>
        </div>
        <div>
          <div className="grid grid-cols-1 gap-[17.38px] md:grid-cols-2">
            <div
              className={clsx([
                "bg-white",
                "rounded-[26.0769px]",
                "sectioncard",
                "px-[20.86px]",
                "pt-[38px]",
                "pb-[60px]",
                "lg:max-w-[273.81px]",
              ])}
            >
              <div className="mb-[22.6px]">
                <img src={res} alt="image" />
              </div>
              <div className="mb-[8.85px]">
                <h3 className="changa-md text-[17.3846px] leading-[32px] ">
                  Responsibility sharing
                </h3>
              </div>
              <div>
                <p className="text-[12.1692px] leading-[196.5%]">
                  Burdens can be reduced, especially while the firm is still
                  young and resources are scarce, by having a cofounder assist
                  you with a certain area of the business.
                </p>
              </div>
            </div>
            <div
              className={clsx([
                "bg-white",
                "rounded-[26.0769px]",
                "sectioncard",
                "px-[20.86px]",
                "pt-[38px]",
                "pb-[60px]",
                "lg:max-w-[273.81px]",
              ])}
            >
              <div className="mb-[22.6px]">
                <img src={skill} alt="image" />
              </div>
              <div className="mb-[8.85px]">
                <h3 className="changa-md text-[17.3846px] leading-[32px] ">
                  Overlapping skill sets
                </h3>
              </div>
              <div>
                <p className="text-[12.1692px] leading-[196.5%]">
                  What abilities a potential co-founder will bring to the table
                  is one of the most crucial considerations. It will feel like
                  wind in your sails to have them supply the crucial missing
                  element for successfully starting or running your firm.
                </p>
              </div>
            </div>
            <div
              className={clsx([
                "bg-white",
                "rounded-[26.0769px]",
                "sectioncard",
                "px-[20.86px]",
                "pt-[38px]",
                "pb-[60px]",
                "lg:max-w-[273.81px]",
              ])}
            >
              <div className="mb-[22.6px]">
                <img src={launch} alt="image" />
              </div>
              <div className="mb-[8.85px]">
                <h3 className="changa-md text-[17.3846px] leading-[32px]">
                  Shorter launch time
                </h3>
              </div>
              <div>
                <p className="text-[12.1692px] leading-[196.5%]">
                  Starting a business requires a lot of sweat, blood, and tears
                  from the founders. You will reach MVP more quickly if you have
                  a co-founder with double the dedication.
                </p>
              </div>
            </div>
            <div
              className={clsx([
                "bg-white",
                "rounded-[26.0769px]",
                "sectioncard",
                "px-[20.86px]",
                "pt-[38px]",
                "pb-[60px]",
                "lg:max-w-[273.81px]",
              ])}
            >
              <div className="mb-[22.6px]">
                <img src={risk} alt="image" />
              </div>
              <div className="mb-[8.85px]">
                <h3 className="changa-md text-[17.3846px] leading-[32px] ">
                  Reduce the risk to investors
                </h3>
              </div>
              <div>
                <p className="text-[12.1692px] leading-[196.5%]">
                  Investors are alerted to increased danger when an investment
                  is dependent on a single founder, with no co-founder to fall
                  back on.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thirdsection;
