import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import error from '../assets/images/bro.svg';

const NotFound = () => {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });
	return (
		<>
			{isTabletOrMobile ? (
				<div className='h-screen bg-[#F8F9FC] flex justify-center py-20 px-10 changa'>
				<div className='text-center'>
						<h1 className='text-[#6C6BD4] changa-bold text-5xl mb-2'>Oops!</h1>
						<h1 className='text-[#373737] changa-bold text-xl mb-8'>
							We couldn&apos;t find what you were looking for
						</h1>
						<p className='text-normal'>
							It&apos;s not your fault, it&apos;s ours. We are working to restore it and get you back on track.
							Please send us a
						</p>
						<p className='text-normal'>
							message through our <span className='text-[#6C6BD4] cursor-pointer'>support channels</span> and let
							us know how you got here.
						</p>
						<div className='flex justify-center items-center mt-20'>
							<img
								src={error}
								alt=''
							/>
						</div>
						<div className='flex justify-center items-center mt-[200px] gap-2 text-[#6C6BD4] font-semibold'>
						<Link to="/">
						<div>
							<p className='cursor-pointer'>Home</p>
						</div>
						</Link>
						<div>
							<p className='cursor-pointer'>Help Center</p>
						</div>
						<div>
							<p className='cursor-pointer'>Blog</p>
						</div>
						<div>
							<p className='cursor-pointer'>Contact Us</p>
						</div>
						<div>
							<p className='cursor-pointer'>FAQs</p>
						</div>
					</div>
					</div>
				</div>
			) : (
				<div className='h-screen bg-[#F8F9FC] flex justify-center py-20 px-10'>
					<div className='text-center'>
						<h1 className='text-[#6C6BD4] changa-bold text-5xl mb-2'>Oops!</h1>
						<h1 className='text-[#373737] changa-bold text-4xl mb-8'>
							We couldn&apos;t find what you were looking for
						</h1>
						<p className='text-normal'>
							It&apos;s not your fault, it&apos;s ours. We are working to restore it and get you back on track.
							Please send us a
						</p>
						<p className='text-normal'>
							message through our <span className='text-[#6C6BD4] cursor-pointer'>support channels</span> and let
							us know how you got here.
						</p>
						<div className='flex justify-center items-center mt-20'>
							<img
								src={error}
								alt=''
							/>
						</div>
					<div className='flex justify-center items-center mt-[200px] gap-4 text-[#6C6BD4] font-semibold'>
						<Link to="/">
						<div>
							<p className='cursor-pointer'>Home</p>
						</div>
						</Link>
						<div>
							<p className='cursor-pointer'>Help Center</p>
						</div>
						<div>
							<p className='cursor-pointer'>Blog</p>
						</div>
						<div>
							<p className='cursor-pointer'>Contact Us</p>
						</div>
						<div>
							<p className='cursor-pointer'>FAQs</p>
						</div>
					</div>
					</div>
				</div>
			)}
		</>
	);
};

export default NotFound;
