import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

export const SkeletonLoader = () => {
  const skeletonColor = '#ccc'; // Gray color

  return (
    <div className='bg-white w-[428px] h-full rounded-lg px-10 pt-11 pb-8 relative mb-5'>
      <div className='flex items-center gap-4 mb-2'>
        <Skeleton circle width={66} height={66} color={skeletonColor} />
        <div className=''>
          <div className='mb-1.5'>
            <Skeleton width={120} height={20} color={skeletonColor} />
          </div>
          <div className='flex items-center gap-1'>
            <Skeleton width={15} height={20} color={skeletonColor} />
            <Skeleton width={160} height={20} color={skeletonColor} />
          </div>
        </div>
      </div>

      <div className='flex items-center justify-center gap-2 mb-7'>
        <Skeleton width={120} height={30} color={skeletonColor} />
      </div>

      <div>
        <div className='flex items-center gap-2 mb-6'>
          <Skeleton width={15} height={20} color={skeletonColor} />
          <Skeleton width={300} height={20} color={skeletonColor} />
        </div>

        <div className='mb-3'>
          <h1 className='changa-bold text-[#0F0F0F] text-sm'>
            <Skeleton width={80} height={20} color={skeletonColor} />
          </h1>
          <Skeleton count={7} color={skeletonColor} />
        </div>

        <div className='flex justify-center items-center gap-8'>
          <div className='back'>
            <Skeleton circle width={50} height={50} color={skeletonColor} />
          </div>
          <div className='dislike'>
            <Skeleton circle width={50} height={50} color={skeletonColor} />
          </div>
          <div className='like'>
            <Skeleton circle width={50} height={50} color={skeletonColor} />
          </div>
          <div className='profile'>
            <Skeleton circle width={50} height={50} color={skeletonColor} />
          </div>
        </div>
      </div>
    </div>
  );
};
