import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import logo from '../../assets/images/createaccountlogo.svg';
import avatar from '../../assets/images/upload-big.svg';
import balloneBig from '../../assets/images/Ellipse-1.svg';
import balloneSmall from '../../assets/images/Ellipse-2.svg';
import mobilelogo from '../../assets/images/logomobile.svg';
import cloud from '../../assets/images/cloud.svg';
import BASE_URL from '../../services/index.js';

const UploadDp = () => {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });
	const navigate = useNavigate();
	const email = localStorage.getItem('userEmail');
	const [fileInput, setFileInput] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const [error, setError] = useState(null);
	const [avatarImage, setAvatarImage] = useState(null);

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		setSelectedFile(file);
		setFileInput(file);

		const reader = new FileReader();
		reader.onload = () => {
			setAvatarImage(reader.result);
		};
		reader.readAsDataURL(file);
	};

	const loginHandler = () => {
		if (!fileInput) {
			setError('Please choose an image to upload.');
			setTimeout(() => {
				setError('');
			}, 10500);
			return;
		}

		const formdata = new FormData();
		formdata.append('email', email);
		formdata.append('image', fileInput);

		fetch(BASE_URL + `/auth/upload`, {
			method: 'POST',
			body: formdata,
			redirect: 'follow',
		})
			.then((response) => response.text())
			.then((result) => {
				//console.log(result);
				navigate('/login', { replace: true });
			})
			.catch((error) => console.log('error', error));
	};

	return (
		<div>
			{isTabletOrMobile ? (
				<div className='px-5 py-12 bg-white changa'>
					<div className='mb-10'>
						<img
							src={mobilelogo}
							alt=''
						/>
					</div>
					<div className='flex justify-center items-center'>
						<div>
							<div className='flex justify-center items-center mb-7'>
								{avatarImage ? (
									<img
										className='w-[100px] h-[100px] rounded-full object-cover'
										src={avatarImage}
										alt='Selected Avatar'
									/>
								) : (
									<img
										src={avatar}
										alt='Default Avatar'
									/>
								)}
							</div>
							<div className='text-center'>
								<h2 className='text-xl leading-[35px] changa-bold'>You’re all set!</h2>
								<h2 className='mb-1 text-xl leading-[35px] changa-bold'>
									Take a minute to upload a profile photo.
								</h2>
								<p className=' text-[#373737] text-base'>Please make sure your photo clearly shows your face.</p>
							</div>
							<div className='mt-16'>
								<div>
									<div className='mt-6 w-full py-3 flex justify-center items-center space-x-4 mb-[29px] text-lg text-[#5F5F5F] bg-[#fff] border border-gray-300 rounded-lg'>
										<label
											htmlFor='upload'
											className=''>
											<div className='flex items-center gap-2'>
												<div>
													<img
														src={cloud}
														alt=''
													/>
												</div>
												<div>Select image</div>
											</div>
										</label>
										<input
											id='upload'
											type='file'
											className='hidden'
											accept='.jpg, .jpeg, .png'
											onChange={handleFileChange}
										/>
									</div>
								</div>
								{selectedFile && <p className='mt-2 text-gray-600'>Selected file: {selectedFile.name}</p>}
								<div>
									<div
										onClick={loginHandler}
										className='mt-6 inputbox w-full py-3 flex justify-center items-center space-x-4 mb-[29px] text-lg text-white bg-[#414080]'>
										Submit
									</div>
								</div>
							</div>
						</div>
					</div>
					<AnimatePresence>
						{error && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg'>
								<p>{error}</p>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			) : (
				<div className='flex justify-center items-center min-h-screen bg-[#F8F9FC] changa'>
					<div className='mt-8'>
						<Link to='/'>
							<div className='mb-[28.63px]'>
								<img
									src={logo}
									alt=''
								/>
							</div>
						</Link>

						<div className='relative'>
							<div className='absolute z-10 -top-8 -right-16 sm:top-4 sm:-right-16'>
								<img
									className='w-56 h-56'
									src={balloneBig}
									alt='Ballone'
								/>
							</div>

							<div className='relative z-20 container mx-auto py-14 px-10 mb-14 bg-white rounded-lg shadow-[20px_40px_100px_rgba(148,148,148,0.1)]'>
								<div>
									<div className='mb-9 text-center'>
										<div className='flex justify-center mb-4'>
											{avatarImage ? (
												<img
													className='w-[100px] h-[100px] rounded-full object-cover'
													src={avatarImage}
													alt='Selected Avatar'
												/>
											) : (
												<img
													src={avatar}
													alt='Default Avatar'
												/>
											)}
										</div>
										<h2 className='text-[19px] leading-[35px] font-semibold'>You’re all set!</h2>
										<h2 className='mb-1 text-normal leading-[35px] font-semibold'>
											Take a minute to upload a profile photo.
										</h2>
										<p className='max-w-[240px] mx-auto text-[#373737] text-xs'>
											Please make sure your photo clearly shows your face.
										</p>
									</div>

									<div>
										<div className='mt-6  w-[417px] py-3 flex justify-center items-center space-x-4 mb-[29px] text-lg text-[#5F5F5F] bg-[#fff] border border-gray-300 rounded-lg cursor-pointer'>
											<label
												htmlFor='upload'
												className=''>
												<div className='flex items-center gap-2'>
													<div>
														<img
															src={cloud}
															alt=''
														/>
													</div>
													<div>Select image</div>
												</div>
											</label>
											<input
												id='upload'
												type='file'
												className='hidden'
												accept='.png, .jpeg, .jpg'
												onChange={handleFileChange}
											/>
										</div>
									</div>
									{selectedFile && <p className='mt-2 text-gray-600'>Selected file: {selectedFile.name}</p>}
									<div>
										<div
											onClick={loginHandler}
											className='mt-6 inputbox w-[417px] py-3 flex justify-center items-center space-x-4 mb-[29px] text-lg text-white bg-[#414080] cursor-pointer'>
											Submit
										</div>
									</div>
								</div>
							</div>

							<div className='absolute z-10 -bottom-10 -left-20'>
								<img
									className='w-36 h-36'
									src={balloneSmall}
									alt='Ballone'
								/>
							</div>
						</div>
					</div>
				</div>
			)}
			<AnimatePresence>
				{error && (
					<motion.div
						initial={{ opacity: 0, x: '100%' }}
						animate={{ opacity: 1, x: 0 }}
						exit={{ opacity: 0, x: '100%' }}
						transition={{ duration: 0.3 }}
						className='text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg'>
						<p>{error}</p>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default UploadDp;
