import clsx from 'clsx';
import { useState, useEffect } from 'react';

import heroimage from '../../assets/images/heroimage.svg';
import '../../assets/css/Hero.css';
import Modal from '../GetStartedModal';

const Hero = () => {
	const [userEmail, setUserEmail] = useState('');
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		localStorage.setItem('userEmail', userEmail);
	}, [userEmail]);

	const handleGetStarted = (e) => {
		e.preventDefault();
		setShowModal(true);
	};

	const closeModal = () => {
		setShowModal(false);
	};

	return (
		<div
			id='top'
			className='flex flex-col items-center pt-8 xl:flex-row xl:pt-4 xl:justify-between'>
			<div className='pb-8 lg:pt-3 lg:pb-6 xl:pt-8 xl:w-3/4 xl:pb-0'>
				<div className='pb-5 font-normal changa-bold text-2xl md:text-3xl lg:pb-3 lg:text-5xl'>
					<p className='leading-[154%] text-[#5655AA]'>Find your Perfect Co-Founder</p>
					<p className='leading-[154%]'>and Take Your Startup to the</p>
					<p className='leading-[154%] text-[#5655AA]'>Next Level</p>
				</div>{' '}
				{/*  */}
				<div className='pb-6 lg:pb-8'>
					<p className='max-w-md lg:max-w-[540px]'>
						Unlock Your Potentials and Reach New Goals with the Right Co-Founder: Find the Perfect Match for
						Your Startup Here!
					</p>
				</div>{' '}
				{/*  */}
				<div>
					<form className='flex flex-col space-y-4 lg:flex-row lg:space-y-0'>
						{/* Your existing code for the email input and "Get Started" button */}
						<input
							type='email'
							value={userEmail}
							onChange={(e) => {
								const newValue = e.target.value;
								setUserEmail(newValue);
								localStorage.setItem('userEmail', newValue);
							}}
							className='border rounded-md p-2 w-full h-[70px] pl-[36px] outline-none placeholder:text-[#373737] placeholder:font-normal leading-[33px] inputbox mr-[21px] lg:w-[427px] lg:h-[90px] lg:text-lg border-gray-300'
							placeholder='Enter your Email Address'
							autoComplete='email'
						/>
						<button
							onClick={handleGetStarted}
							className='w-full h-[65px] text-lg bg-[#414080] rounded-[8px] text-white lg:w-[184px] lg:h-[87px] cursor-pointer'>
							Get Started
						</button>
					</form>
				</div>
				{/* Modal */}
				{/* The modal will be displayed when the `showModal` state is true */}
				{showModal && <Modal closeModal={closeModal} />}
			</div>
			<div className='xl:w-1/2'>
				<img
					className='w-full'
					src={heroimage}
					alt='image of man in corporate dress'
				/>
			</div>
		</div>
	);
};

export default Hero;
