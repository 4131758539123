import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import axios from "axios";

import QuestionsYes from './QuestionsYes';
import QuestionsNo from './QuestionsNo';
import logo from '../../assets/images/createaccountlogo.svg';
import balloneBig from '../../assets/images/Ellipse-1.svg';
import balloneSmall from '../../assets/images/Ellipse-2.svg';
import mobilelogo from '../../assets/images/logomobile.svg';
import BASE_URL from '../../services';

export const Label = ({ html, title }) => {
	return (
		<label
			className='font-[400] text-[14.3883px] leading-[26px] tracking-[0.025em] text-[#1A1A33]'
			htmlFor={html}>
			{title}
		</label>
	);
};

const Questions = () => {
	const [selectedOption, setSelectedOption] = useState('');
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });

	const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
	};

	return (
		<>
			{isTabletOrMobile ? (
				<>
					<div className='bg-white px-2 py-12 changa'>
						<div className='mb-12'>
							<img
								src={mobilelogo}
								alt=''
							/>
						</div>
						<div className='mb-8'>
							<h2 className='mb-7 changa-bold text-[19.106px] leading-[0.02em] text-[#1A1A33]'>
								Almost there!
							</h2>
							<p className='mb-3'>Do you have a startup?</p>
							<div className='flex items-center space-x-3'>
								<input
									type='radio'
									id='Yes'
									value='Yes'
									checked={selectedOption === 'Yes'}
									onChange={handleOptionChange}
								/>
								<Label
									html='Yes'
									title='Yes'
								/>
							</div>
							<div className='flex items-center space-x-3'>
								<input
									type='radio'
									id='No'
									value='No'
									checked={selectedOption === 'No'}
									onChange={handleOptionChange}
								/>
								<Label
									html='No'
									title='No'
								/>
							</div>
						</div>
						{selectedOption === 'Yes' && <QuestionsYes />}
						{selectedOption === 'No' && <QuestionsNo />}
					</div>
				</>
			) : (
				<>
					<div className='flex justify-center items-center min-h-screen bg-[#F8F9FC] changa pt-5 pb-32'>
						<div className='mt-8'>
							<Link to='/'>
								<div className='mb-[28.63px]'>
									<img
										src={logo}
										alt=''
									/>
								</div>
							</Link>
							<div className='relative'>
								<div className='absolute z-10 -top-8 -right-16 sm:top-4 sm:-right-16'>
									<img
										className='w-56 h-56'
										src={balloneBig}
										alt='Ballone'
									/>
								</div>

								<div className='relative z-20 container mx-auto py-14 px-10 mb-14 bg-white rounded-lg shadow-[20px_40px_100px_rgba(148,148,148,0.1)]'>
									<div className='mb-8'>
										<h2 className='mb-7 font-semibold text-[19.106px] leading-[0.02em] text-[#1A1A33]'>
											Almost there!
										</h2>
										<p className='mb-3'>Do you have a startup?</p>
										<div className='flex items-center space-x-3'>
											<input
												type='radio'
												id='Yes'
												value='Yes'
												checked={selectedOption === 'Yes'}
												onChange={handleOptionChange}
											/>
											<Label
												html='Yes'
												title='Yes'
											/>
										</div>
										<div className='flex items-center space-x-3'>
											<input
												type='radio'
												id='No'
												value='No'
												checked={selectedOption === 'No'}
												onChange={handleOptionChange}
											/>
											<Label
												html='No'
												title='No'
											/>
										</div>
									</div>

									{selectedOption === 'Yes' && <QuestionsYes />}
									{selectedOption === 'No' && <QuestionsNo />}
								</div>

								<div className='absolute z-10 -bottom-10 -left-20'>
									<img
										className='w-36 h-36'
										src={balloneSmall}
										alt='Ballone'
									/>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default Questions;
