import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { useState, useEffect } from 'react';
import clsx from 'clsx';

import logo from '../../assets/images/createaccountlogo.svg';
import balloneBig from '../../assets/images/Ellipse-1.svg';
import balloneSmall from '../../assets/images/Ellipse-2.svg';
import mobilelogo from '../../assets/images/logomobile.svg';
import BASE_URL from '../../services/index';
import eyeclose from '../../assets/images/eye-hide.svg';
import eyeopen from '../../assets/images/eye-open.svg';

const endpoint = '/auth/reset-password';

const ResetPassword = () => {
	const email = sessionStorage.getItem('forgotPasswordEmail');
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [isFormValid, setIsFormValid] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [response, setResponse] = useState('');
	const [error, setError] = useState('');
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });
	const navigate = useNavigate();

	const togglePasswordVisibility = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};

	const submitPasswordHandler = (e) => {
		e.preventDefault();

		setIsSubmitting(true);
		const data = {
			email,
			newPassword,
			confirmNewPassword,
		};
		axios
			.patch(BASE_URL + endpoint, data)
			.then((response) => {
				console.log(response.data);
				setResponse(response.data.Message);
				setNewPassword('');
				setConfirmNewPassword('');
				setIsSubmitting(false);
				setTimeout(() => {
					navigate('/success', {replace: true});
				}, 7000);
			})
			.catch((error) => {
				console.error(error);
				setError(error.response.data.Message);
				setIsSubmitting(false);
				// setNewPassword('');
				// setConfirmNewPassword('');
				setTimeout(() => {
					setError('');
				}, 10500);
			});
	};

	useEffect(() => {
		setIsFormValid(newPassword !== '' && confirmNewPassword !== '');
	}, [newPassword, confirmNewPassword]);

	return (
		<>
			{isTabletOrMobile ? (
				<div className='px-5 py-12 bg-white changa'>
					<div className='mb-10'>
						<img
							src={mobilelogo}
							alt=''
						/>
					</div>
					<div className='mb-9'>
						<h2 className='mb-1 text-[19px] leading-[35px] changa-bold'>Reset your password</h2>
					</div>
					<div>
						<form onSubmit={submitPasswordHandler}>
							<div className='flex flex-col mb-3'>
								<label
									className='font-[400] text-[14.3883px] leading-[26px] tracking-[0.025em] text-[#1A1A33]'
									htmlFor='email-phone'>
									New password
								</label>
								<div className='relative w-full h-[52px] rounded-[8px] border border-1 border-[#CFCFCF] p-3 mt-[8px] mb-3'>
							<input
								id='password'
								required
								type={showPassword ? 'text' : 'password'}
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
								className='w-full h-full outline-none'
							/>
							<div
								className='absolute top-3 right-2 transition-all duration-300 text-gray-500'
								onClick={togglePasswordVisibility}>
								{showPassword ? (
									<img
										src={eyeclose}
										alt=''
									/>
								) : (
									<img
										src={eyeopen}
										alt=''
									/>
								)}
							</div>
						</div>
							</div>
							<div className='flex flex-col mb-3'>
								<label
									className='font-[400] text-[14.3883px] leading-[26px] tracking-[0.025em] text-[#1A1A33]'
									htmlFor='email-phone'>
									Confirm new pasword
								</label>
								<div className='relative w-full h-[52px] rounded-[8px] border border-1 border-[#CFCFCF] p-3 mt-[8px] mb-14'>
							<input
								id='password'
								required
								type={showPassword ? 'text' : 'password'}
								value={confirmNewPassword}
								onChange={(e) => setConfirmNewPassword(e.target.value)}
								title='Password should include an uppercase, lowercase, special characters and digits (minimum 8 characters)'
								className='w-full h-full outline-none'
							/>
							<button
								className='absolute top-3 right-2 transition-all duration-300 text-gray-500'
								onClick={togglePasswordVisibility}>
								{showPassword ? (
									<img
										src={eyeclose}
										alt=''
									/>
								) : (
									<img
										src={eyeopen}
										alt=''
									/>
								)}
							</button>
						</div>
							</div>
							<button
								className={clsx([
									'w-full h-[49px] bg-[#414080] text-[#FFFFFF] rounded-[8px]',
									{
										'cursor-not-allowed opacity-50': !isFormValid || isSubmitting,
									},
								])}
								disabled={!isFormValid || isSubmitting}>
								{isSubmitting ? 'Changing...' : 'Change Password'}
							</button>
						</form>
					</div>
					<AnimatePresence>
						{response && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg'>
								<p>{response}</p>
							</motion.div>
						)}
					</AnimatePresence>
					{/*  */}
					<AnimatePresence>
						{error && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg'>
								<p>{error}</p>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			) : (
				<div className='flex justify-center items-center min-h-screen bg-[#F8F9FC] changa'>
					<div className='mt-8'>
						<Link to='/'>
							<div className='mb-[28.63px]'>
								<img
									src={logo}
									alt=''
								/>
							</div>
						</Link>

						<div className='relative'>
							<div className='absolute z-10 -top-8 -right-16 sm:top-4 sm:-right-16'>
								<img
									className='w-56 h-56'
									src={balloneBig}
									alt='Ballone'
								/>
							</div>

							<div className='relative z-20 container mx-auto py-14 px-10 mb-14 bg-white rounded-lg shadow-[20px_40px_100px_rgba(148,148,148,0.1)]'>
								<div>
									<div className='mb-6'>
										<h2 className='mb-1 text-[19px] leading-[35px] font-semibold'>Reset your password</h2>
									</div>

									<form onSubmit={submitPasswordHandler}>
										<div className='flex flex-col'>
											<label
												className='font-[400] text-[14.3883px] leading-[26px] tracking-[0.025em] text-[#1A1A33]'
												htmlFor='email-phone'>
												New Password
											</label>
											<div className='relative w-[417px] h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-3'>
											<input
												id='password'
												required
												type={showPassword ? 'text' : 'password'}
												value={newPassword}
												onChange={(e) => setNewPassword(e.target.value)}
												className='w-full h-full outline-none'
											/>
											<div
												className='absolute top-3 right-2 transition-all duration-300 text-gray-500'
												onClick={togglePasswordVisibility}>
												{showPassword ? (
													<img
														src={eyeclose}
														alt=''
													/>
												) : (
													<img
														src={eyeopen}
														alt=''
													/>
												)}
											</div>
										</div>
										</div>
										<div className='flex flex-col mb-3'>
											<label
												className='font-[400] text-[14.3883px] leading-[26px] tracking-[0.025em] text-[#1A1A33]'
												htmlFor='email-phone'>
												Confirm New Password
											</label>
											<div className='relative w-[417px] h-[52px] rounded-[8px] inputbox p-3 mt-[8px]'>
											<input
												id='password'
												required
												type={showPassword ? 'text' : 'password'}
												value={confirmNewPassword}
												onChange={(e) => setConfirmNewPassword(e.target.value)}
												className='w-full h-full outline-none'
											/>
											<div
												className='absolute top-3 right-2 transition-all duration-300 text-gray-500'
												onClick={togglePasswordVisibility}>
												{showPassword ? (
													<img
														src={eyeclose}
														alt=''
													/>
												) : (
													<img
														src={eyeopen}
														alt=''
													/>
												)}
											</div>
										</div>
										</div>

										<button
											type='submit'
											className={clsx([
												'w-full h-[49px] bg-[#414080] text-[#FFFFFF] rounded-[8px] mt-12',
												{
													'cursor-not-allowed opacity-50': !isFormValid || isSubmitting,
												},
											])}
											disabled={!isFormValid || isSubmitting}>
											{isSubmitting ? 'Changing...' : 'Change password'}
										</button>
									</form>
								</div>
							</div>

							<div className='absolute z-10 -bottom-10 -left-20'>
								<img
									className='w-36 h-36'
									src={balloneSmall}
									alt='Ballone'
								/>
							</div>
						</div>
					</div>
					<AnimatePresence>
						{response && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg'>
								<p>{response}</p>
							</motion.div>
						)}
					</AnimatePresence>
					{/*  */}
					<AnimatePresence>
						{error && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg'>
								<p>{error}</p>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			)}
		</>
	);
};

export default ResetPassword;
