import TermsAccordion from "./components/terms-and-conditions/accordions"
import { Layout } from "./layout"

const Terms = () => {
  return (
    <Layout>
			<div className='px-5 md:px-14 py-9 md:py-10 bg-[#FAFBFD]'>
				<div>
					<h1 className='changa-bold text-5xl mb-5 md:mb-20 text-[#5655AA]'>Terms and Conditions</h1>
				</div>
				<div>
					<TermsAccordion />
				</div>
			</div>
		</Layout>
  )
}

export default Terms