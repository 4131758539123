import { Layout } from './layout';
import PrivacyAccordion from './components/privacy/accordion';

const Privacy = () => {
	return (
		<Layout>
			<div className='px-5 md:px-14 py-9 md:py-10 bg-[#FAFBFD]'>
				<div>
					<h1 className='changa-bold text-5xl mb-5 md:mb-20 text-[#5655AA]'>Privacy Policy</h1>
				</div>
				<div>
					<PrivacyAccordion />
				</div>
			</div>
		</Layout>
	);
};

export default Privacy;