export const skillOptions = [
  'React.js',
  'Next.js',
  'JavaScript',
  'HTML/CSS',
  'Node.js',
  'Python',
  'Java',
  'C#',
  'Ruby',
  'PHP',
  'SQL',
  'MongoDB',
  'Redux',
  'Vue.js',
  'Angular',
  'UI/UX Design',
  'Responsive Web Design',
  'Version Control (e.g., Git)',
  'RESTful API Development',
  'GraphQL',
  'Docker',
  'AWS/Azure/GCP (Cloud Services)',
  'DevOps',
  'Agile/Scrum',
  'Project Management',
  'Machine Learning',
  'Data Analysis',
  'Cybersecurity',
  'Digital Marketing',
  'Content Management Systems (e.g., WordPress)',
  'Mobile App Development (e.g., React Native, Flutter)',
  'Database Administration',
  'Blockchain',
  'IoT (Internet of Things)',
  'AR/VR Development',
  'Game Development',
  'Linux/Unix',
  'Network Administration',
  'Technical Writing',
  'Communication Skills',
  'Problem-Solving',
  'Teamwork',
  'Leadership',
  'Time Management',
  'Adaptability',
  'Customer Service',
  'Sales',
  'Foreign Languages',
];
