import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadingSkeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import { useAuth } from '../../../hooks/useAuthentication';
import ex from '../../../assets/images/ex.svg';
import notification from '../../../assets/images/notification.svg';
import BASE_URL from '../../../services/index.js';
import useUser from '../../../hooks/useUser';
import successTick from '../../../assets/images/success-tick.svg';
import left from '../../../assets/images/left.svg';
import right from '../../../assets/images/right.svg';
import useFeedback from '../../../hooks/useFeedback';

const RightSidebar = () => {
	const { logout } = useAuth();
	const { user } = useUser();
	const [isLoading, setIsLoading] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [currentContent, setCurrentContent] = useState(1);
	const [isContentCompleted, setIsContentCompleted] = useState(false);
	const { feedback } = useFeedback();
	const [showButtons, setShowButtons] = useState(true);

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	const closeModal = () => {
		setShowModal(false);
		setCurrentContent(1);
		setIsContentCompleted(false);

		const feedbackData = {
			rating: feedback.selectedValue,
			reason: feedback.reason,
			product_quality: feedback.product,
			customer_support: feedback.customer,
			tips: feedback.advise,
		};

		// console.log(feedbackData)

		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${user.token}`,
		};

		axios
			.post(BASE_URL + '/account/create-feedback', feedbackData, { headers })
			.then((response) => {
				console.log('Feedback successfully submitted:', response.data);
			})
			.catch((error) => {
				console.error('Error submitting feedback:', error);
			});
	};

	useEffect(() => {
		setShowButtons(currentContent !== 6);
	}, [currentContent]);

	return (
		<div className='h-screen bg-white w-[263px] px-5 py-12'>
			<div className='flex justify-center mb-16'>
				<div className=''>
					<div className='mb-4 flex justify-center'>
						<img
							className='rounded-full w-[90px] h-[90px] object-cover'
							src={user.dp}
							alt=''
						/>
					</div>
					<div>
						<p className='capitalize font-semibold'>{user.name}</p>
					</div>
					<div>
						<p className='uppercase font-semibold text-center text-[#CD7F32]'>{user.tier}</p>
					</div>
				</div>
			</div>

			{/*  */}

			<div className='blur mt-40 mb-40'>
				<div className='flex justify-between items-center mb-16'>
					<div>
						<p className='capitalize changa-bold text-lg text-black'>reminders</p>
					</div>
					<div className='cursor-pointer'>
						<img
							src={notification}
							alt=''
						/>
					</div>
				</div>
				{/*  */}
				<div className='flex items-center gap-3'>
					<p className='text-center text-[#5F5F5F]'>
						Click the plus (+) button in the sidebar to set a meeting reminder
					</p>
				</div>
			</div>

			<div className='flex justify-center items-center'>
				<FeedbackButton onClick={toggleModal} />
			</div>

			{/* Modal */}
			{showModal && (
				<div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center'>
					<div className='bg-white p-5 rounded-lg w-[460px]'>
						<div className='flex justify-end items-center'>
							<div
								className='cursor-pointer'
								onClick={toggleModal}>
								<img
									src={ex}
									alt=''
								/>
							</div>
						</div>
						<>
							{currentContent === 1 && <Content1 handleCompletion={setIsContentCompleted} />}
							{currentContent === 2 && <Content2 handleCompletion={setIsContentCompleted} />}
							{currentContent === 3 && <Content3 handleCompletion={setIsContentCompleted} />}
							{currentContent === 4 && <Content4 handleCompletion={setIsContentCompleted} />}
							{currentContent === 5 && <Content5 handleCompletion={setIsContentCompleted} />}
							{currentContent === 6 && <Content6 closeModal={closeModal} />}
						</>
						<div className=''>
							{showButtons && (
								<div className='flex justify-between items-center mb-5'>
									<button
										onClick={() => setCurrentContent(currentContent - 1)}
										className={`bg-[#414080] px-6 py-2 text-white rounded-lg cursor-pointer ${
											currentContent === 1 ? 'cursor-not-allowed opacity-50' : ''
										}`}
										disabled={currentContent === 1}>
										<img
											src={left}
											alt='previous'
										/>
									</button>
									<button
										disabled={!isContentCompleted}
										onClick={() => setCurrentContent(currentContent + 1)}
										className={`bg-[#414080] px-6 py-2 text-white rounded-lg  ${
											!isContentCompleted ? 'cursor-not-allowed opacity-50' : ''
										}`}>
										<img
											src={right}
											alt='next'
										/>
									</button>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default RightSidebar;

export const FeedbackButton = ({ onClick }) => {
	return (
		<button
			onClick={onClick}
			className='bg-[#414080] px-9 py-2.5 text-white changa rounded-lg cursor-pointer'>
			Give Feedback
		</button>
	);
};

const Content1 = ({ handleCompletion }) => {
	const [selectedValue, setSelectedValue] = useState(null);
	const { feedback, setFeedback } = useFeedback();

	const handleValueSelection = (value) => {
    setSelectedValue(value);
    handleCompletion(true);
  };

  useEffect(() => {
    if (selectedValue !== null) {
      setFeedback({ selectedValue });
    }
  }, [selectedValue, setFeedback]);

	// console.log({ feedback });

	return (
		<div>
			<h1 className='text-lg text-[#555555] mt-2 mb-9'>
				On a scale of 1-10, how likely are you to recommend
				<br /> our company to a friend or colleague?
			</h1>
			<div className='flex justify-between items-center mb-2'>
				{[...Array(10)].map((_, index) => (
					<div
						key={index}
						className={`border rounded-full h-[30px] w-[30px] flex justify-center items-center cursor-pointer hover:shadow-lg hover:scale-125 transition-all duration-300 ${
							selectedValue === index + 1 ? 'bg-gray-400' : ''
						}`}
						onClick={() => handleValueSelection(index + 1)}>
						{index + 1}
					</div>
				))}
			</div>
			<div className='flex justify-between items-center mb-5'>
				<div>
					<p className='text-[#BE1919]'>Not at all likely</p>
				</div>
				<div>
					<p className='text-[#18C975]'>Extremely likely</p>
				</div>
			</div>
		</div>
	);
};

const Content2 = ({ handleCompletion }) => {
	const [reason, setReason] = useState();
	const { feedback, setFeedback } = useFeedback();

	useEffect(() => {
		handleCompletion(false);
	}, []);

	const handleChange = (e) => {
		setReason(e.target.value);
		setFeedback((prev) => ({ ...prev, reason }));
		handleCompletion(!!e.target.value.trim());
	};

	// console.log({ feedback });

	return (
		<div>
			<h1 className='text-lg text-[#555555] mt-2 mb-7'>Please tell us why you give a rating</h1>
			<textarea
				name='reason'
				required
				onChange={handleChange}
				className='w-[418px] outline-none border border-gray-300 rounded-lg mb-9 p-1 h-[112px]'></textarea>
		</div>
	);
};

const Content3 = ({ handleCompletion }) => {
	const [selectedOption, setSelectedOption] = useState('');
	const { feedback, setFeedback } = useFeedback();

	useEffect(() => {
		handleCompletion(false);
	}, []);

	const handleOptionClick = (option) => {
		setSelectedOption(option);
		setFeedback((prev) => ({ ...prev, product: option }));
		handleCompletion(!!option);
	};

	// console.log({ feedback });

	return (
		<div>
			<h1 className='text-lg text-[#555555] mt-2 mb-6'>
				How satisfied are you with the following experience with our company ?
			</h1>
			<div className='mb-4'>
				<h1 className='changa-semibold text-xl mb-6'>Product quality</h1>
				<div className='mb-5'>
					<div
						className={`bg-[#F4F4F4] px-4 py-4 mb-2 rounded-lg cursor-pointer ${
							selectedOption === 'Extremely satisfied' ? 'border border-[#454488]' : ''
						}`}
						onClick={() => handleOptionClick('Extremely satisfied')}>
						Extremely satisfied
					</div>
					<div
						className={`bg-[#F4F4F4] px-4 py-4 mb-2 rounded-lg cursor-pointer ${
							selectedOption === 'Moderately satisfied' ? 'border border-[#454488]' : ''
						}`}
						onClick={() => handleOptionClick('Moderately satisfied')}>
						Moderately satisfied
					</div>
					<div
						className={`bg-[#F4F4F4] px-4 py-4 mb-2 rounded-lg cursor-pointer ${
							selectedOption === 'Neither satisfied nor dissatisfied' ? 'border border-[#454488]' : ''
						}`}
						onClick={() => handleOptionClick('Neither satisfied nor dissatisfied')}>
						Neither satisfied nor dissatisfied
					</div>
					<div
						className={`bg-[#F4F4F4] px-4 py-4 mb-2 rounded-lg cursor-pointer ${
							selectedOption === 'Moderately dissatisfied' ? 'border border-[#454488]' : ''
						}`}
						onClick={() => handleOptionClick('Moderately dissatisfied')}>
						Moderately dissatisfied
					</div>
					<div
						className={`bg-[#F4F4F4] px-4 py-4 mb-2 rounded-lg cursor-pointer ${
							selectedOption === 'Extremely dissatisfied' ? 'border border-[#454488]' : ''
						}`}
						onClick={() => handleOptionClick('Extremely dissatisfied')}>
						Extremely dissatisfied
					</div>
				</div>
			</div>
		</div>
	);
};

const Content4 = ({ handleCompletion }) => {
	const [selectedOption, setSelectedOption] = useState('');
	const { feedback, setFeedback } = useFeedback();

	useEffect(() => {
		handleCompletion(false);
	}, []);

	const handleOptionClick = (option) => {
		setSelectedOption(option);
		setFeedback((prev) => ({ ...prev, customer: option }));
		handleCompletion(!!option);
	};

	// console.log({ feedback });

	return (
		<div>
			<h1 className='text-lg text-[#555555] mt-2 mb-6'>
				How satisfied are you with the following experience
				<br /> with our company ?
			</h1>
			<div>
				<h1 className='changa-semibold text-xl mb-6'>Customer support</h1>
				<div className='mb-5'>
					<div
						className={`bg-[#F4F4F4] px-4 py-4 mb-2 rounded-lg cursor-pointer ${
							selectedOption === 'Extremely satisfied' ? 'border border-[#454488]' : ''
						}`}
						onClick={() => handleOptionClick('Extremely satisfied')}>
						Extremely satisfied
					</div>
					<div
						className={`bg-[#F4F4F4] px-4 py-4 mb-2 rounded-lg cursor-pointer ${
							selectedOption === 'Moderately satisfied' ? 'border border-[#454488]' : ''
						}`}
						onClick={() => handleOptionClick('Moderately satisfied')}>
						Moderately satisfied
					</div>
					<div
						className={`bg-[#F4F4F4] px-4 py-4 mb-2 rounded-lg cursor-pointer ${
							selectedOption === 'Neither satisfied nor dissatisfied' ? 'border border-[#454488]' : ''
						}`}
						onClick={() => handleOptionClick('Neither satisfied nor dissatisfied')}>
						Neither satisfied nor dissatisfied
					</div>
					<div
						className={`bg-[#F4F4F4] px-4 py-4 mb-2 rounded-lg cursor-pointer ${
							selectedOption === 'Moderately dissatisfied' ? 'border border-[#454488]' : ''
						}`}
						onClick={() => handleOptionClick('Moderately dissatisfied')}>
						Moderately dissatisfied
					</div>
					<div
						className={`bg-[#F4F4F4] px-4 py-4 mb-2 rounded-lg cursor-pointer ${
							selectedOption === 'Extremely dissatisfied' ? 'border border-[#454488]' : ''
						}`}
						onClick={() => handleOptionClick('Extremely dissatisfied')}>
						Extremely dissatisfied
					</div>
				</div>
			</div>
		</div>
	);
};

const Content5 = ({ handleCompletion }) => {
	const [advise, setAdvise] = useState();
	const { feedback, setFeedback } = useFeedback();

	useEffect(() => {
		handleCompletion(false);
	}, []);

	const handleChange = (e) => {
		setAdvise(e.target.value);
		setFeedback((prev) => ({ ...prev, advise }));
		handleCompletion(!!e.target.value.trim());
	};

	// console.log({ feedback });

	return (
		<div>
			<h1 className='text-lg text-[#555555] mt-2 mb-7'>
				Is there anything specific that our company can do
				<br /> to improve your experience?
			</h1>
			<textarea
				name='advise'
				required
				onChange={handleChange}
				className='w-[418px] outline-none border border-gray-300 rounded-lg mb-9 p-1 h-[112px]'></textarea>
		</div>
	);
};

const Content6 = ({ closeModal }) => (
	<div>
		<div className='flex justify-center items-center'>
			<div>
				<div className='flex justify-center items-center mb-7'>
					<img
						className=''
						src={successTick}
						alt='Success Tick'
					/>
				</div>
				<div className='text-center'>
					<h2 className='mb-1 text-xl leading-[35px] changa-bold text-[#454488]'>
						Thanks for your feedback!
					</h2>
					<p className=' text-[#373737] text-base'>
						We appreciate your contribution to our
						<br /> app's enhancement
					</p>
				</div>
				<div className='mt-10 mb-10'>
					<button
						onClick={closeModal}
						className='w-full h-[49px] bg-[#414080] text-[#FFFFFF] rounded-[8px]'>
						Return to Dashboard
					</button>
				</div>
			</div>
		</div>
	</div>
);
