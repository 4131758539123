export const locationData = [
  { city: 'New York', country: 'United States' },
  { city: 'Paris', country: 'France' },
  { city: 'London', country: 'United Kingdom' },
  { city: 'Sydney', country: 'Australia' },
  { city: 'Tokyo', country: 'Japan' },
  { city: 'Beijing', country: 'China' },
  { city: 'Cairo', country: 'Egypt' },
  { city: 'Lagos', country: 'Nigeria' },
  { city: 'Uyo', country: 'Nigeria' },
  { city: 'Akure', country: 'Nigeria' },
  { city: 'Eket', country: 'Nigeria' },
  { city: 'Ondo', country: 'Nigeria' },
  { city: 'Abuja', country: 'Nigeria' },
  { city: 'Kano', country: 'Nigeria' },
  { city: 'Port Harcourt', country: 'Nigeria' },
  { city: 'Ibadan', country: 'Nigeria' },
  { city: 'Enugu', country: 'Nigeria' },
  { city: 'Benin City', country: 'Nigeria' },
  { city: 'Kaduna', country: 'Nigeria' },
  { city: 'Calabar', country: 'Nigeria' },
  { city: 'Warri', country: 'Nigeria' },
  { city: 'Jos', country: 'Nigeria' },
  { city: 'Abeokuta', country: 'Nigeria' },
  { city: 'Maiduguri', country: 'Nigeria' },
  { city: 'Katsina', country: 'Nigeria' },
  { city: 'Aba', country: 'Nigeria' },
  { city: 'Owerri', country: 'Nigeria' },
  { city: 'Sokoto', country: 'Nigeria' },
  { city: 'Ilorin', country: 'Nigeria' },
  { city: 'Ado Ekiti', country: 'Nigeria' },
  { city: 'Onitsha', country: 'Nigeria' },
  { city: 'Umuahia', country: 'Nigeria' },
  { city: 'Ikeja', country: 'Nigeria' },
  { city: 'Abeokuta', country: 'Nigeria' },
  { city: 'Minna', country: 'Nigeria' },
  { city: 'Oshogbo', country: 'Nigeria' },
  { city: 'Yenagoa', country: 'Nigeria' },
  { city: 'Lafia', country: 'Nigeria' },
  { city: 'Gombe', country: 'Nigeria' },
  { city: 'Asaba', country: 'Nigeria' },
  { city: 'Birnin Kebbi', country: 'Nigeria' },
  { city: 'Lokoja', country: 'Nigeria' },
  { city: 'Makurdi', country: 'Nigeria' },
  { city: 'Bauchi', country: 'Nigeria' },
  { city: 'Yola', country: 'Nigeria' },
  { city: 'Jalingo', country: 'Nigeria' },
  { city: 'Gusau', country: 'Nigeria' },
  { city: 'Ijebu Ode', country: 'Nigeria' },
  { city: 'Sagamu', country: 'Nigeria' },
  { city: 'Nnewi', country: 'Nigeria' },
  { city: 'Okene', country: 'Nigeria' },
  { city: 'Ede', country: 'Nigeria' },
  { city: 'Iwo', country: 'Nigeria' },
  { city: 'Ajaokuta', country: 'Nigeria' },
  { city: 'Gwagwalada', country: 'Nigeria' },
  { city: 'Ijebu Igbo', country: 'Nigeria' },
  { city: 'Gboko', country: 'Nigeria' },
  { city: 'Arua', country: 'Nigeria' },
  { city: 'Sapele', country: 'Nigeria' },
  { city: 'Kisi', country: 'Nigeria' },
  { city: 'Gashua', country: 'Nigeria' },
  { city: 'Mubi', country: 'Nigeria' },
  { city: 'Okrika', country: 'Nigeria' },
  { city: 'Kumo', country: 'Nigeria' },
  { city: 'Elele', country: 'Nigeria' },
  { city: 'Keffi', country: 'Nigeria' },
  { city: 'Gwaram', country: 'Nigeria' },
  { city: 'Ilorin', country: 'Nigeria' },
  { city: 'Ilorin', country: 'Nigeria' },
  { city: 'Kisi', country: 'Nigeria' },
  { city: 'Gashua', country: 'Nigeria' },
  { city: 'Mubi', country: 'Nigeria' },
  { city: 'Okrika', country: 'Nigeria' },
  { city: 'Kumo', country: 'Nigeria' },
  { city: 'Elele', country: 'Nigeria' },
  { city: 'Keffi', country: 'Nigeria' },
  { city: 'Gwaram', country: 'Nigeria' },
  { city: 'Jega', country: 'Nigeria' },
  { city: 'Kazaure', country: 'Nigeria' },
  { city: 'Bama', country: 'Nigeria' },
  { city: 'Dutse', country: 'Nigeria' },
  { city: 'Igboho', country: 'Nigeria' },
  { city: 'Oke Mesi', country: 'Nigeria' },
  { city: 'Ikirun', country: 'Nigeria' },
  { city: 'Lapai', country: 'Nigeria' },
  { city: 'Bida', country: 'Nigeria' },
  { city: 'Malumfashi', country: 'Nigeria' },
  { city: 'Kachia', country: 'Nigeria' },
  { city: 'Tegina', country: 'Nigeria' },
  { city: 'Kukawa', country: 'Nigeria' },
  { city: 'Zungeru', country: 'Nigeria' },
  { city: 'Dikwa', country: 'Nigeria' },
  { city: 'Wukari', country: 'Nigeria' },
  { city: 'Birnin Gwari', country: 'Nigeria' },
  { city: 'Ezza', country: 'Nigeria' },
  { city: 'Azare', country: 'Nigeria' },
  { city: 'Ikerre', country: 'Nigeria' },
  { city: 'Lafia', country: 'Nigeria' },
  { city: 'Ogoja', country: 'Nigeria' },
  { city: 'Ijero Ekiti', country: 'Nigeria' },
  { city: 'Birnin Gwari', country: 'Nigeria' },
  { city: 'Sapele', country: 'Nigeria' },
  { city: 'Kisi', country: 'Nigeria' },
  { city: 'Gashua', country: 'Nigeria' },
  { city: 'Mubi', country: 'Nigeria' },
  { city: 'Okrika', country: 'Nigeria' },
  { city: 'Kumo', country: 'Nigeria' },
  { city: 'Elele', country: 'Nigeria' },
  { city: 'Keffi', country: 'Nigeria' },
  { city: 'Gwaram', country: 'Nigeria' },
  { city: 'Jega', country: 'Nigeria' },
  { city: 'Kazaure', country: 'Nigeria' },
  { city: 'Bama', country: 'Nigeria' },
  { city: 'Dutse', country: 'Nigeria' },
  { city: 'Igboho', country: 'Nigeria' },
  { city: 'Oke Mesi', country: 'Nigeria' },
  { city: 'Ikirun', country: 'Nigeria' },
  { city: 'Lapai', country: 'Nigeria' },
  { city: 'Bida', country: 'Nigeria' },
  { city: 'Malumfashi', country: 'Nigeria' },
  { city: 'Kachia', country: 'Nigeria' },
  { city: 'Tegina', country: 'Nigeria' },
  { city: 'Kukawa', country: 'Nigeria' },
  { city: 'Zungeru', country: 'Nigeria' },
  { city: 'Dikwa', country: 'Nigeria' },
  { city: 'Wukari', country: 'Nigeria' },
  { city: 'Birnin Gwari', country: 'Nigeria' },
  { city: 'Ezza', country: 'Nigeria' },
  { city: 'Azare', country: 'Nigeria' },
  { city: 'Ikerre', country: 'Nigeria' },
  { city: 'Lafia', country: 'Nigeria' },
  { city: 'Ogoja', country: 'Nigeria' },
  { city: 'Ijero Ekiti', country: 'Nigeria' },
  { city: 'Oturkpo', country: 'Nigeria' },
  { city: 'Potiskum', country: 'Nigeria' },
  { city: 'Gwadar', country: 'Nigeria' },
  { city: 'Kafanchan', country: 'Nigeria' },
  { city: 'Kisi', country: 'Nigeria' },
  { city: 'Gashua', country: 'Nigeria' },
  { city: 'Mubi', country: 'Nigeria' },
  { city: 'Okrika', country: 'Nigeria' },
  { city: 'Kumo', country: 'Nigeria' },
  { city: 'Elele', country: 'Nigeria' },
  { city: 'Keffi', country: 'Nigeria' },
  { city: 'Gwaram', country: 'Nigeria' },
  { city: 'Jega', country: 'Nigeria' },
  { city: 'Kazaure', country: 'Nigeria' },
  { city: 'Bama', country: 'Nigeria' },
  { city: 'Dutse', country: 'Nigeria' },
  { city: 'Igboho', country: 'Nigeria' },
  { city: 'Oke Mesi', country: 'Nigeria' },
  { city: 'Ikirun', country: 'Nigeria' },
  { city: 'Lapai', country: 'Nigeria' },
  { city: 'Bida', country: 'Nigeria' },
  { city: 'Malumfashi', country: 'Nigeria' },
  { city: 'Kachia', country: 'Nigeria' },
  { city: 'Tegina', country: 'Nigeria' },
  { city: 'Kukawa', country: 'Nigeria' },
  { city: 'Zungeru', country: 'Nigeria' },
  { city: 'Dikwa', country: 'Nigeria' },
  { city: 'Wukari', country: 'Nigeria' },
  { city: 'Birnin Gwari', country: 'Nigeria' },
  { city: 'Ezza', country: 'Nigeria' },
  { city: 'Azare', country: 'Nigeria' },
  { city: 'Ikerre', country: 'Nigeria' },
  { city: 'Lafia', country: 'Nigeria' },
  { city: 'Ogoja', country: 'Nigeria' },
  { city: 'Ijero Ekiti', country: 'Nigeria' },
  { city: 'Oturkpo', country: 'Nigeria' },
  { city: 'Potiskum', country: 'Nigeria' },
  { city: 'Gwadar', country: 'Nigeria' },
  { city: 'Kafanchan', country: 'Nigeria' },
  { city: 'Birnin Kudu', country: 'Nigeria' },
  { city: 'Doma', country: 'Nigeria' },
  { city: 'Isieke', country: 'Nigeria' },
  { city: 'Kontagora', country: 'Nigeria' },
  { city: 'Ijebu Jesa', country: 'Nigeria' },
  { city: 'Ilobu', country: 'Nigeria' },
  { city: 'Lafia', country: 'Nigeria' },
  { city: 'Ogoja', country: 'Nigeria' },
  { city: 'Ijero Ekiti', country: 'Nigeria' },
  { city: 'Oturkpo', country: 'Nigeria' },
  { city: 'Potiskum', country: 'Nigeria' },
  { city: 'Gwadar', country: 'Nigeria' },
  { city: 'Kafanchan', country: 'Nigeria' },
  { city: 'Birnin Kudu', country: 'Nigeria' },
  { city: 'Doma', country: 'Nigeria' },
  { city: 'Isieke', country: 'Nigeria' },
  { city: 'Kontagora', country: 'Nigeria' },
  { city: 'Ijebu Jesa', country: 'Nigeria' },
  { city: 'Ilobu', country: 'Nigeria' },
  { city: 'Numan', country: 'Nigeria' },
  { city: 'Lapai', country: 'Nigeria' },
  { city: 'Kwale', country: 'Nigeria' },
  { city: 'Degema', country: 'Nigeria' },
  { city: 'Funtua', country: 'Nigeria' },
  { city: 'Oke Ila', country: 'Nigeria' },
  { city: 'Wamba', country: 'Nigeria' },
  { city: 'Abejukolo', country: 'Nigeria' },
]