import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const FeedbackContext = createContext({});

export const FeedbackProvider = ({ children }) => {
	const [feedback, setFeedback] = useState({});

	return (
		<FeedbackContext.Provider value={{ feedback, setFeedback }}>{children}</FeedbackContext.Provider>
	);
};

export default FeedbackContext;

FeedbackProvider.propTypes = {
	children: PropTypes.element,
};
