import React from 'react';
import clsx from 'clsx';

import '../../assets/css/Landingpage.css';
import wait from '../../assets/images/forcardone.svg';
import search from '../../assets/images/forcardtwo.svg';
import quality from '../../assets/images/forcardthree.svg';

const Secondsection = () => {
	return (
		<div className='mt-[103px] grid grid-cols-1 gap-4 mb-[98px] md:grid-cols-2 lg:grid-cols-3 lg:mt-[156px] lg:mb-[166px]'>
			<div
				className={clsx([
					'flex justify-center items-center py-12 px-10 bg-white topcardshadow rounded-[5px]',
				])}>
				<div className=''>
					<div className='mb-[25px]'>
						<img
							src={wait}
							alt='illustrations'
						/>
					</div>
					<div>
						<h3 className='changa-semibold text-lg leading-[33px] mb-[11px] lg:text-[22px] lg:leading-[40px]'>
							Lots of co-founders await
						</h3>
						<p className='leading-[33px] text-[#2E2E2] lg:text-lg'>
							Join the most comprehensive co-founder matching site to find the best fit for you.
						</p>
					</div>
				</div>
			</div>
			<div
				className={clsx([
					'flex justify-center items-center py-12 px-10 bg-white topcardshadow rounded-[5px]',
				])}>
				<div className=''>
					<div className='mb-[25px]'>
						<img
							src={search}
							alt='illustrations'
						/>
					</div>
					<div>
						<h3 className='changa-semibold text-lg leading-[33px] mb-[11px] lg:text-[22px] lg:leading-[40px]'>
							Couple of matches have been made
						</h3>
						<p className='leading-[33px] text-[#2E2E2E] lg:text-lg'>
							Depending on what you are searching for, we&apos;ll pair you with co-founders who complement you best.
						</p>
					</div>
				</div>
			</div>
			<div
				className={clsx([
					'flex justify-center items-center py-12 px-10 bg-white topcardshadow rounded-[5px]',
				])}>
				<div>
					<div className='mb-[25px]'>
						<img
							src={quality}
							alt='illustrations'
						/>
					</div>
					<div>
						<h3 className='changa-semibold text-lg leading-[33px] mb-[11px] lg:text-[22px] lg:leading-[40px]'>
							Pre-screened, top-quality founders
						</h3>
						<p className='leading-[33px] text-[#2E2E2E] lg:text-lg'>
							To make sure everyone gets a good match, we thorougly interview founders
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Secondsection;
