import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';

import Footer from '../../layout/footer/Footer';
import logo from '../../assets/images/helpailogo.svg';
import logomobile from '../../assets/images/logomobile.svg';
import BASE_URL from '../../services/index.js';

const CompleteProfile = () => {
	const navigate = useNavigate();

	const handleResendClick = () => {
		navigate('/questions');
	};

	return (
		<>
			<div className='bg-[#FAFBFD] px-10 py-5 h-[650px] changa'>
				<div className='mb-10'>
					<img
						src={logo}
						srcSet={logomobile}
						alt='Description of the image'
					/>
				</div>
				<div className='flex flex-col justify-center text-center'>
					<h1 className='text-[#454488] text-3xl md:text-6xl changa-bold mb-5'>Profile not verified</h1>
					<p className='text-base md:text-xl'>
						To continue, click on Verify Profile now to gain access to your dashboard
					</p>
				</div>
				<div className='flex justify-center'>
					<div className='mt-16'>
						<div className='mb-6'>
							<button
								onClick={handleResendClick}
								className={`w-[247px] px-5 py-3 mb-4 md:mb-0 bg-[#414080] rounded-lg text-white cursor-pointer`}>
								Verify Profile
							</button>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default CompleteProfile;
