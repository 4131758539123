import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';


const NavButton = ({ onClick, children }) => {
	return (
		<>
			<button
				onClick={onClick}
				className={clsx(['createbutton', 'w-[205px]', 'h-[57px]', 'rounded-[6px]', 'hover:bg-[#414080]', 'hover:text-white', 'transition-all', 'duration-300'])}>
				<p className='font-600 leading-[33px]'>{children}</p>
			</button>
		</>
	);
};

export default NavButton;
