import React from "react";

import formimage from "../../assets/images/Registration form image.svg";
import dash from "../../assets/images/Dashboard image.svg";
import profile from "../../assets/images/Co-founder profile image.svg";
import match from "../../assets/images/Match illustration image.svg";

const InfoHeirachy = () => {
  return (
    <div id="user-guide-section" className="mb-24 lg:mb-28">
      <div className="flex justify-center items-center mb-8 lg:mb-12">
        <h2 className="changa-bold font-medium text-[24px] leading-[59px] text-[#1A1A1A] lg:text-[32px]">
          How does it work ?
        </h2>
      </div>
      <div className="flex justify-center">
        <div className="flex flex-col space-y-4 lg:block lg:space-y-0">
          <CreateProfile />
          <Coprofile />
          <Connect />
          <Matched />
        </div>
      </div>
    </div>
  );
};

export default InfoHeirachy;

const CreateProfile = () => {
  return (
    <div className="flex flex-col justify-center gap-[40px] lg:flex-row lg:items-center lg:gap-[165px]">
      <div className="lg:max-w-[480px]">
        <h2 className="changa-md text-2xl leading-[48px] text-[#414080] lg:text-[26px]">
          Create a profile
        </h2>
        <p>
          Enter your email above to create an account, fill in the details about
          you, your company and your preferences for a co-founder
        </p>
      </div>
      <div className="w-full flex justify-center">
        <img
          className="sm:w-4/5 md:w-3/5 lg:w-full"
          src={formimage}
          alt="form image"
        />
      </div>
    </div>
  );
};

const Coprofile = () => {
  return (
    <div className="flex flex-col-reverse justify-center gap-[40px] lg:flex-row lg:items-center lg:gap-[165px]">
      <div className="w-full flex justify-center">
        <img
          className="sm:w-4/5 md:w-3/5 lg:w-full"
          src={dash}
          alt="form image"
        />
      </div>
      <div className="lg:max-w-[480px]">
        <h2 className="changa-md text-2xl leading-[48px] text-[#414080] lg:text-[26px]">
          View co-founders’ profiles
        </h2>
        <p>
          Once you’re registered, we’ll show you profiles that fit your
          preferences
        </p>
      </div>
    </div>
  );
};

const Connect = () => {
  return (
    <div className="flex flex-col justify-center gap-[40px] lg:flex-row lg:items-center lg:gap-[165px]">
      <div className="lg:max-w-[480px]">
        <h2 className="changa-md text-2xl leading-[48px] text-[#414080] lg:text-[26px]">
          Connect with a co-founder
        </h2>
        <p>
          If a profile piques your interest, click on the message request button
          to send an automated or personalized message to invite them to
          connect.
        </p>
      </div>
      <div className="w-full flex justify-center">
        <img
          className="sm:w-4/5 md:w-3/5 lg:w-full"
          src={profile}
          alt="form image"
        />
      </div>
    </div>
  );
};

const Matched = () => {
  return (
    <div className="flex flex-col-reverse justify-center gap-[40px] lg:flex-row lg:items-center lg:gap-[165px]">
      <div className="w-full flex justify-center">
        <img
          className="sm:w-4/5 md:w-3/5 lg:w-full"
          src={match}
          alt="form image"
        />
      </div>
      <div className="lg:max-w-[480px]">
        <h2 className="changa-md text-2xl leading-[48px] text-[#414080] lg:text-[26px]">
          Get matched!
        </h2>
        <p>
          If your invite/request is accepted, then you have successfully matched
          with a co-founder! You can now schedule a meeting and begin working
          together.
        </p>
      </div>
    </div>
  );
};
