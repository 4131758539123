import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";

import logo from "../../assets/images/createaccountlogo.svg";
import balloneBig from "../../assets/images/Ellipse-1.svg";
import balloneSmall from "../../assets/images/Ellipse-2.svg";
import mobilelogo from "../../assets/images/logomobile.svg";
import uploadButton from "../../assets/images/new-upload.svg";
import BASE_URL from "../../services/index.js";
import { industryOptions } from "../../assets/staticdata/industry";
import { skillOptions } from "../../assets/staticdata/skillsOption";
import { locationData } from "../../assets/staticdata/location.js";
import { useProfileData } from "../../services/getProfileService";
import useUser from "../../hooks/useUser.jsx";

export const Label = ({ html, title }) => {
  return (
    <label
      className="font-[400] text-[14.3883px] leading-[26px] tracking-[0.025em] text-[#1A1A33]"
      htmlFor={html}
    >
      {title}
    </label>
  );
};

const EditProfessionalDetails = () => {
  const email = localStorage.getItem("userEmail");
  const { user } = useUser();
  const [portfolio, setPortfolio] = useState("");
  const [industry, setIndustry] = useState("");
  const [resume, setResume] = useState("");
  const [elevatorPitch, setElevatorPitch] = useState("");
  const [skillDemanded, setSkillDemanded] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [response, setResponse] = useState("");
  const [error, setError] = useState("");
  const [userLocation, setUserLocation] = useState();
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [wordCount, setWordCount] = useState(0);
  const [showindustryOptions, setshowindustryOptions] = useState(false);
  const [showSkillsOptions, setShowSkillsOptions] = useState(false);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [showLocationSuggestions, setShowLocationSuggestions] = useState(false);
  const { profileData } = useProfileData();

  const toggleIndustryOptions = () => {
    setshowindustryOptions(!showindustryOptions);
  };

  const handleSelectIndustry = (stage) => {
    setIndustry(stage);
    setshowindustryOptions(false);
  };

  const toggleSkillsOptions = () => {
    setShowSkillsOptions(!showSkillsOptions);
  };

  const handleSelectSkill = (skill) => {
    if (!Array.isArray(skillDemanded)) {
      setSkillDemanded([skill]);
    } else if (skillDemanded.includes(skill)) {
      setSkillDemanded(
        skillDemanded.filter((selectedSkill) => selectedSkill !== skill)
      );
    } else if (skillDemanded.length < 3) {
      setSkillDemanded([...skillDemanded, skill]);
    }
  };

  const endpoint = "/account/settings/edit-profile";

  const updateWordCount = (value) => {
    const words = value.trim().split(/\s+/);
    setWordCount(words.length);
  };

  const setElevatorPitchValue = (value) => {
    if (value.length <= 400) {
      setElevatorPitch(value);
      updateWordCount(value);
    }
  };

  const filterLocationSuggestions = (query) => {
    const filteredSuggestions = locationData.filter((location) =>
      `${location.city}, ${location.country}`
        .toLowerCase()
        .includes(query.toLowerCase())
    );
    setLocationSuggestions(filteredSuggestions);
  };

  const handleLocationInputChange = (event) => {
    const { value } = event.target;
    setUserLocation(value);
    filterLocationSuggestions(value);
  };

  const handleSelectLocation = (location) => {
    setUserLocation(location);
    setShowLocationSuggestions(false);
  };

  useEffect(() => {
    if (locationSuggestions.length > 0) {
      setShowLocationSuggestions(true);
    } else {
      setShowLocationSuggestions(false);
    }
  }, [locationSuggestions]);

  // const handleFileChange = (event) => {
  // 	const file = event.target.files[0];
  // 	setSelectedFile(file);
  // 	console.log(selectedFile, file);
  // 	setResume(file.name); // Set resume as the selected file name
  // };

  useEffect(() => {
    if (profileData) {
      setElevatorPitch(profileData.data.elevatorPitch);
      setPortfolio(profileData.data.portfolio);
      // setUserLocation(profileData.data.userLocation);
    }
  }, [profileData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    const data = new FormData();
    data.append("elevatorPitch", elevatorPitch);
    data.append("industry", industry);
    skillDemanded.forEach((skill, index) => {
      data.append(`skillDemanded[${index}]`, skill);
    });
    data.append("userLocation", userLocation);
    data.append("portfolio", portfolio);
    if (selectedFile) {
      data.append("resume", selectedFile, selectedFile.name);
    }

    axios
      .post(BASE_URL + endpoint, data, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setElevatorPitch("");
        setIndustry("");
        setSkillDemanded([]);
        setUserLocation("");
        setPortfolio("");
        setSelectedFile(null);
        setResponse(response.data.Message);
        setIsSubmitting(false);
        setTimeout(() => {
          navigate("/profile", { replace: true });
        }, 9000);
      })
      .catch((error) => {
        console.error(error);
        setIsSubmitting(false);
        setError(error.response.data.Message);
        setIsSubmitting(false);
        setTimeout(() => {
          setError("");
        }, 5000);
      });
  };

  // const isFormValid = elevatorPitch && skillDemanded && industry && userLocation && portfolio;

  return (
    <>
      {isTabletOrMobile ? (
        <>
          <div className="bg-white px-2 py-11 changa">
            <div className="mb-12">
              <Link to="/settings">
                <h1 className="text-xl text-[#5655AA]">Back</h1>
              </Link>
            </div>
            <div className="mb-5">
              <h1 className="changa-bold text-lg">
                Edit your Professional details
              </h1>
            </div>

            <form onSubmit={handleSubmit} className=" flex flex-col mb-[25px]">
              <Label html="Elevator pitch" title="Edit Elevator pitch" />
              <textarea
                required
                type="text"
                value={elevatorPitch}
                onChange={(e) => setElevatorPitchValue(e.target.value)}
                placeholder="Talk about yourself"
                className="bg-white rounded-lg border border-1 border-[#CFCFCF] outline-none w-full px-6 py-3 h-[110px]"
              />
              <p className="mb-5">{`${elevatorPitch.length}/400 characters`}</p>

              <button
                type="button"
                onClick={toggleSkillsOptions}
                className={clsx([
                  isTabletOrMobile ? "w-full" : "w-[417px]",
                  "h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px] border-[1.03915px] border-[#AFAFAF] appearance-none flex items-center justify-between focus:border-[#414080] focus:outline-none",
                ])}
              >
                {skillDemanded.length === 0
                  ? "Edit Desired skills"
                  : skillDemanded.slice(0, 3).map((selectedSkill, index) => (
                      <span
                        key={index}
                        className="bg-purple-500 text-white rounded-full px-2 py-1 mr-2"
                      >
                        {selectedSkill}
                      </span>
                    ))}
                {skillDemanded.length > 3 && (
                  <span className="ml-2">+{skillDemanded.length - 3}</span>
                )}
                <span className="ml-2">
                  {/* Custom arrow icon */}
                  <svg
                    className={clsx([
                      "w-5 h-5",
                      showSkillsOptions && "transform rotate-180",
                      "text-[#AFAFAF]",
                    ])}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </button>

              {/*  */}
              {showSkillsOptions && (
                <div className="absolute top-[405px] z-10 w-full mt-1 py-1 px-4 bg-white border border-[#E6E6E6] rounded-lg h-[140px] overflow-y-auto">
                  {skillOptions.map((skill) => (
                    <button
                      key={skill}
                      type="button"
                      onClick={() => handleSelectSkill(skill)}
                      className={clsx([
                        "w-full p-3 text-left hover:bg-[#F5F5FF] rounded-md",
                        Array.isArray(skillDemanded) &&
                          skillDemanded.includes(skill) &&
                          "bg-[#F5F5FF] text-[#414080] font-semibold",
                      ])}
                    >
                      {skill}
                    </button>
                  ))}
                </div>
              )}

              <button
                type="button"
                onClick={toggleIndustryOptions}
                className={clsx([
                  isTabletOrMobile ? "w-full" : "w-[417px]",
                  "h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px] border-[1.03915px] border-[#AFAFAF] appearance-none flex items-center justify-between focus:border-[#414080] focus:outline-none",
                ])}
              >
                {industry || "Edit Industry"}
                <span className="ml-2">
                  {/* Custom arrow icon */}
                  <svg
                    className={clsx([
                      "w-5 h-5",
                      showindustryOptions && "transform rotate-180",
                      "text-[#AFAFAF]",
                    ])}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </button>
              {/*  */}
              {showindustryOptions && (
                <div
                  className={clsx([
                    "absolute z-10 top-[475px] w-full mt-1 py-1 px-4 bg-white border border-[#E6E6E6] rounded-lg",
                  ])}
                >
                  {industryOptions.map((stage) => (
                    <button
                      key={stage}
                      type="button"
                      onClick={() => handleSelectIndustry(stage)}
                      className={clsx([
                        "w-full p-3 text-left hover:bg-[#F5F5FF] rounded-md",
                      ])}
                    >
                      {stage}
                    </button>
                  ))}
                </div>
              )}

              <Label html="Location" title="Location" />
              <input
                required
                type="text"
                value={userLocation}
                onChange={handleLocationInputChange}
                placeholder="Search your location"
                className="bg-white rounded-lg border border-1 border-[#CFCFCF] outline-none w-full px-6 py-3 mb-5"
              />
              {showLocationSuggestions && (
                <div className="absolute z-10 top-[530px] w-[399px] mt-1 py-1 px-4 bg-white border border-[#E6E6E6] rounded-lg">
                  <ul className="suggestion-list">
                    {locationSuggestions.map((suggestion, index) => (
                      <li
                        className="w-full p-3 text-left hover:bg-[#F5F5FF] rounded-md cursor-pointer"
                        key={index}
                        onClick={() =>
                          handleSelectLocation(
                            `${suggestion.city}, ${suggestion.country}`
                          )
                        }
                      >
                        {suggestion.city}, {suggestion.country}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <Label
                html="Link to your portfolio"
                title="Edit Portfolio link"
              />
              <input
                required
                type="text"
                value={portfolio}
                onChange={(e) => setPortfolio(e.target.value)}
                placeholder="https://your-portfolio-link.com"
                className="bg-white rounded-lg border border-1 border-[#CFCFCF] outline-none w-full px-6 py-3 mb-5"
              />
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-col justify-center">
                  <Label html="Resume" title="Edit resume" />
                  <div className="flex gap-3 items-center">
                    <div className="">
                      <div className="mt-2">
                        <div>
                          <label
                            htmlFor="upload"
                            className="flex items-center text-black bg-transparent border border-[#454488] rounded-md cursor-pointer px-4 py-3 gap-x-2"
                          >
                            <img src={uploadButton} alt="Upload" className="" />
                            <p className="text-[#454488]">Upload file</p>
                          </label>
                          <input
                            id="upload"
                            type="file"
                            name="resume"
                            accept=".pdf, .docx, .doc"
                            onChange={(e) => setSelectedFile(e.target.files[0])}
                            className="hidden"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 w-[250px] p-2">
                      {selectedFile && (
                        <p className="text-gray-600">
                          Selected file: {selectedFile.name}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <button
                className={clsx([
                  "w-full h-[49px] bg-[#414080] text-[#FFFFFF] rounded-[8px] mt-9",
                  {
                    "cursor-not-allowed opacity-50": isSubmitting,
                  },
                ])}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Updating..." : "Save changes"}
              </button>
            </form>
          </div>
          <AnimatePresence>
            {response && (
              <motion.div
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "100%" }}
                transition={{ duration: 0.3 }}
                className="text-white text-xl px-[21px] py-4 absolute top-1 right-1 bg-[#5655AA] flex justify-center items-center rounded-lg z-10"
              >
                <p>{response}</p>
              </motion.div>
            )}
          </AnimatePresence>
          {/*  */}
          <AnimatePresence>
            {error && (
              <motion.div
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "100%" }}
                transition={{ duration: 0.3 }}
                className="text-white text-xl px-[21px] py-4 absolute top-1 right-1 bg-[#5655AA] flex justify-center items-center rounded-lg z-10"
              >
                <p>{error}</p>
              </motion.div>
            )}
          </AnimatePresence>
        </>
      ) : (
        <div className="bg-[#F8F9FC] h-screen overflow-y-auto flex justify-center text-start changa">
          <div className="mt-[65px]">
            <Link to="/settings">
              <div className="mb-[28.63px]">
                <h1 className="text-[#6C6BD4] text-2xl">Back</h1>
              </div>
            </Link>

            <div className="relative">
              <div className="absolute z-10 -top-8 -right-16 sm:top-4 sm:-right-16">
                <img className="w-56 h-56" src={balloneBig} alt="Ballone" />
              </div>

              <div className="relative z-10 w-[498px] h-[950px] bg-[#FFFFFF] rounded-lg form px-[40px] pt-[40px] pb-16 shadow-[20px_40px_100px_rgba(148,148,148,0.1)]">
                <div className="mb-10">
                  <h2 className="font-semibold text-[19.106px] leading-[0.02em] text-[#1A1A33]">
                    Edit your professional information
                  </h2>
                </div>

                <div className="">
                  <form
                    onSubmit={handleSubmit}
                    className=" flex flex-col mb-[25px]"
                  >
                    <Label html="Elevator Pitch" title="Edit Elevator pitch" />
                    <textarea
                      type="text"
                      id="Elevator Pitch"
                      value={elevatorPitch}
                      onChange={(e) => setElevatorPitchValue(e.target.value)}
                      placeholder="Talk about yourself"
                      className={clsx([
                        "w-[417px] h-[153px] rounded-[8px] inputbox p-3 mt-[8px] border-[1.03915px] border-[#AFAFAF]",
                      ])}
                    />
                    <p className="mb-5">{`${elevatorPitch.length}/400 characters`}</p>
                    {/*  */}
                    <div className="flex flex-col">
                      <Label html="Industry" title="Edit Industry" />
                      <button
                        type="button"
                        onClick={toggleIndustryOptions}
                        className={clsx([
                          isTabletOrMobile ? "w-full" : "w-[417px]",
                          "h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px] border-[1.03915px] border-[#AFAFAF] appearance-none flex items-center justify-between focus:border-[#414080] focus:outline-none",
                        ])}
                      >
                        {industry || "Select Industry"}
                        <span className="ml-2">
                          {/* Custom arrow icon */}
                          <svg
                            className={clsx([
                              "w-5 h-5",
                              showindustryOptions && "transform rotate-180",
                              "text-[#AFAFAF]",
                            ])}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        </span>
                      </button>
                      {/*  */}
                      {showindustryOptions && (
                        <div
                          className={clsx([
                            "absolute z-10 top-[395px] w-[417px] mt-1 py-1 px-4 bg-white border border-[#E6E6E6] rounded-lg",
                          ])}
                        >
                          {industryOptions.map((stage) => (
                            <button
                              key={stage}
                              type="button"
                              onClick={() => handleSelectIndustry(stage)}
                              className={clsx([
                                "w-full p-3 text-left hover:bg-[#F5F5FF] rounded-md",
                              ])}
                            >
                              {stage}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                    {/*  */}
                    <div className="flex flex-col">
                      <Label html="Skills" title="Edit Skills" />
                      <button
                        type="button"
                        onClick={toggleSkillsOptions}
                        className={clsx([
                          isTabletOrMobile ? "w-full" : "w-[417px]",
                          "h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px] border-[1.03915px] border-[#AFAFAF] appearance-none flex items-center justify-between focus:border-[#414080] focus:outline-none",
                        ])}
                      >
                        {!skillDemanded || skillDemanded.length === 0
                          ? "Edit skills"
                          : (Array.isArray(skillDemanded) ? skillDemanded : [])
                              .slice(0, 3)
                              .map((selectedSkill, index) => (
                                <span
                                  key={index}
                                  className="bg-purple-500 text-white rounded-full px-2 py-1 mr-2"
                                >
                                  {selectedSkill}
                                </span>
                              ))}
                        {skillDemanded && skillDemanded.length > 3 && (
                          <span className="ml-2">
                            +{skillDemanded.length - 3}
                          </span>
                        )}
                        <span className="ml-2">
                          {/* Custom arrow icon */}
                          <svg
                            className={clsx([
                              "w-5 h-5",
                              showSkillsOptions && "transform rotate-180",
                              "text-[#AFAFAF]",
                            ])}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        </span>
                      </button>

                      {/*  */}
                      {showSkillsOptions && (
                        <div className="absolute top-[500px] z-10 w-[417px] mt-1 py-1 px-4 bg-white border border-[#E6E6E6] rounded-lg h-[140px] overflow-y-auto">
                          {skillOptions.map((skill) => (
                            <button
                              key={skill}
                              type="button"
                              onClick={() => handleSelectSkill(skill)}
                              className={clsx([
                                "w-full p-3 text-left hover:bg-[#F5F5FF] rounded-md",
                                Array.isArray(skillDemanded) &&
                                  skillDemanded.includes(skill) &&
                                  "bg-[#F5F5FF] text-[#414080] font-semibold",
                              ])}
                            >
                              {skill}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                    {/*  */}
                    <div className="flex flex-col">
                      <Label html="Location" title="Location" />
                      <input
                        type="text"
                        id="Location"
                        value={userLocation}
                        onChange={handleLocationInputChange}
                        placeholder="Search your location"
                        className={clsx([
                          "relative w-[417px] h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px] border-[1.03915px] border-[#AFAFAF]",
                        ])}
                      />
                      {showLocationSuggestions && (
                        <div className="absolute z-10 top-[600px] w-[417px] mt-1 py-1 px-4 bg-white border border-[#E6E6E6] rounded-lg h-[200px] overflow-y-auto">
                          <ul className="">
                            {locationSuggestions.map((suggestion, index) => (
                              <li
                                className="w-full p-3 text-left hover:bg-[#F5F5FF] rounded-md cursor-pointer"
                                key={index}
                                onClick={() =>
                                  handleSelectLocation(
                                    `${suggestion.city}, ${suggestion.country}`
                                  )
                                }
                              >
                                {suggestion.city}, {suggestion.country}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    {/*  */}
                    <Label html="Portfolio" title="Edit portfolio link" />
                    <input
                      type="text"
                      id="Portfolio"
                      value={portfolio}
                      onChange={(e) => setPortfolio(e.target.value)}
                      placeholder="https://your-portfolio-link.com"
                      className={clsx([
                        "w-[417px] h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px] border-[1.03915px] border-[#AFAFAF]",
                      ])}
                    />
                    {/*  */}
                    <div className="flex flex-col justify-center">
                      <Label html="Resume" title="Edit resume" />
                      <div className="flex gap-3 items-center">
                        <div className="">
                          <div className="mt-2">
                            <div>
                              <label
                                htmlFor="upload"
                                className="flex items-center text-black bg-transparent border border-[#454488] rounded-md cursor-pointer px-5 py-3 gap-x-2"
                              >
                                <img
                                  src={uploadButton}
                                  alt="Upload"
                                  className=""
                                />
                                <p className="text-[#454488]">Upload file</p>
                              </label>
                              <input
                                id="upload"
                                type="file"
                                name="resume"
                                accept=".pdf, .docx, .doc"
                                onChange={(e) =>
                                  setSelectedFile(e.target.files[0])
                                }
                                className="hidden"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-5 w-[250px] p-2">
                          {selectedFile && (
                            <p className="text-gray-600">
                              Selected file: {selectedFile.name}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* button */}
                    <button
                      className={clsx([
                        "w-[419px] h-[49px] bg-[#414080] text-white font-semibold rounded-lg mt-14 mb-14",
                        {
                          "cursor-not-allowed opacity-50": isSubmitting,
                        },
                      ])}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Updating..." : "Save changes"}
                    </button>
                  </form>
                  <AnimatePresence>
                    {response && (
                      <motion.div
                        initial={{ opacity: 0, x: "100%" }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: "100%" }}
                        transition={{ duration: 0.3 }}
                        className="text-white text-xl px-[21px] py-4 absolute top-1 right-1 bg-[#5655AA] flex justify-center items-center rounded-lg z-10"
                      >
                        <p>{response}</p>
                      </motion.div>
                    )}
                  </AnimatePresence>
                  {/*  */}
                  <AnimatePresence>
                    {error && (
                      <motion.div
                        initial={{ opacity: 0, x: "100%" }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: "100%" }}
                        transition={{ duration: 0.3 }}
                        className="text-white text-xl px-[21px] py-4 absolute top-1 right-1 bg-[#5655AA] flex justify-center items-center rounded-lg z-10"
                      >
                        <p>{error}</p>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>

              <div className="absolute z-5 -bottom-10 -left-20 sm:-bottom-8">
                <img className="w-36 h-36" src={balloneSmall} alt="Ballone" />
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      )}
    </>
  );
};

export default EditProfessionalDetails;
