import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useState, useEffect } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';

import logo from '../../assets/images/createaccountlogo.svg';
import mobilelogo from '../../assets/images/logomobile.svg';
import BASE_URL from '../../services/index';

const endpoint = '/auth/forgot-password';

const ForgotPassword = () => {
	const [email, setEmail] = useState('');
	const [isFormValid, setIsFormValid] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [response, setResponse] = useState('');
	const [error, setError] = useState('');
	const navigate = useNavigate();
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });

	const getOTPHandler = (event) => {
		event.preventDefault();

		setIsSubmitting(true);
		const data = { email };
		sessionStorage.setItem('forgotPasswordEmail', email);

		axios
			.post(BASE_URL + endpoint, data)
			.then((response) => {
				console.log(response.data);
				setResponse(response.data.message);
				setEmail('');
				setIsSubmitting(false);
				setTimeout(() => {
					navigate('/get-otp', {replace: true});
				}, 5000);
			})
			.catch((error) => {
				// Handle the error
				console.error(error);
				setError(error.response.data.Message);
				setIsSubmitting(false);
				// setEmail('');
				setTimeout(() => {
					setError('');
				}, 10500);
			});
	};

	useEffect(() => {
		setIsFormValid(email !== '');
	}, [email]);

	return (
		<>
			{isTabletOrMobile ? (
				<div className='px-5 py-12 bg-white changa'>
					<div className='mb-10'>
						<img
							src={mobilelogo}
							alt=''
						/>
					</div>
					<div className='mb-9'>
						<h2 className='mb-1 text-[19px] leading-[35px] changa-bold'>Forgot password</h2>
						<p className='max-w-md text-[#373737]'>
							Enter the email address or phone number associated with your account and we’ll send you a code to
							reset your password.
						</p>
					</div>
					<div>
						<form onSubmit={getOTPHandler}>
							<div className='flex flex-col mb-3'>
								<label
									className='font-[400] text-[14.3883px] leading-[26px] tracking-[0.025em] text-[#1A1A33]'
									htmlFor='email-phone'>
									Email
								</label>
								<input
									type='email'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									id='email-phone'
									className=' h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px]'
								/>
							</div>

							<button
								className={clsx([
									'w-full h-[49px] bg-[#414080] text-[#FFFFFF] rounded-[8px]',
									{
										'cursor-not-allowed opacity-50': !isFormValid || isSubmitting,
									},
								])}
								disabled={!isFormValid || isSubmitting}>
								{isSubmitting ? 'Sending...' : 'Get OTP'}
							</button>
						</form>
					</div>
					<div>
						<div className='flex justify-center items-center mt-4'>
							<p>
								Already have an account?{' '}
								<Link to='/login'>
									<span className='text-[#5655AA]'>Login</span>
								</Link>
							</p>
						</div>
					</div>
					<AnimatePresence>
						{response && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg'>
								<p>{response}</p>
							</motion.div>
						)}
					</AnimatePresence>
					{/*  */}
					<AnimatePresence>
						{error && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg'>
								<p>{error}</p>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			) : (
				<div className='flex justify-center items-center min-h-screen bg-[#F8F9FC] p-2 changa'>
					<div className='mt-8'>
						<Link to='/'>
							<div className='mb-[28.63px]'>
								<img
									src={logo}
									alt=''
								/>
							</div>
						</Link>

						<div className='container mx-auto py-14 px-10 mb-14 bg-white rounded-lg relative'>
							<div>
								<div className='mb-9'>
									<h2 className='mb-1 text-[19px] leading-[35px] font-semibold'>Forgot password</h2>
									<p className='max-w-md text-[#373737]'>
										Enter the email address or phone number associated with your account and we&apos;ll send you a code
										to reset your password.
									</p>
								</div>
								<form onSubmit={getOTPHandler}>
									<div className='flex flex-col mb-3'>
										<label
											className='font-[400] text-[14.3883px] leading-[26px] tracking-[0.025em] text-[#1A1A33]'
											htmlFor='email-phone'>
											Email
										</label>
										<input
											type='text'
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											id='email'
											className=' h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px]'
										/>
									</div>

									<button
										type='submit'
										className={clsx([
											'w-full h-[49px] bg-[#414080] text-[#FFFFFF] rounded-[8px]',
											{
												'cursor-not-allowed opacity-50': !isFormValid || isSubmitting,
											},
										])}
										disabled={!isFormValid || isSubmitting}>
										{isSubmitting ? 'Sending...' : 'Get OTP'}
									</button>
								</form>
								<div className='flex justify-center space-x-2 mb-10 text-sm leading-[26px] tracking-[.0.25em] mt-4'>
									<p className='text-[#5F5F5F]'>Don't have an account? </p>
									<Link
										to='/create-account'
										className='text-[#5655AA] font-semibold'>
										Sign up
									</Link>
								</div>
							</div>
						</div>
					</div>
					<AnimatePresence>
						{response && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg'>
								<p>{response}</p>
							</motion.div>
						)}
					</AnimatePresence>
					{/*  */}
					<AnimatePresence>
						{error && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg'>
								<p>{error}</p>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			)}
		</>
	);
};

export default ForgotPassword;
