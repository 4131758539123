import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import Footer from '../../layout/footer/Footer';
import successtick from '../../assets/images/success-tick.svg';
import logo from '../../assets/images/helpailogo.svg';

const EmailVerified = () => {
	const navigate = useNavigate();

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			navigate('/questions');
		}, 5000);

		return () => clearTimeout(timeoutId);
	}, [navigate]);

	return (
		<>
			<div className='changa h-[450px]'>
				<div className='px-3 py-2'>
					<img
						src={logo}
						alt='tick'
					/>
				</div>
				<div className='flex justify-center items-center mb-5'>
					<img
						src={successtick}
						alt='tick'
					/>
				</div>
				<div>
					<h1 className='text-center text-4xl text-[#454488] font-bold changa-bold'>
						Email verification successful
					</h1>
					<div className='flex justify-center'>
						<div className='mt-2 max-w-lg'>
							<p className='text-center text-base text-[#555] '>
								Your email has been successfully verified. You will be redirected automatically in 5 seconds. If not
								redirected, click{' '}
								<span
									onClick={() => {
										navigate('/questions');
									}}
									className='cursor-pointer text-[#6C6BD4]'>
									here
								</span>
							</p>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default EmailVerified;
