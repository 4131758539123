import React from 'react';
import { Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { useAuth } from '../hooks/useAuthentication';

import Navbar from '../layout/header/Navbar';
import Footer from '../layout/footer/Footer';
import Hero from '../components/landingpagesections/Hero';
import Secondsection from '../components/landingpagesections/Secondsection';
import Thirdsection from '../components/landingpagesections/Thirdsection';
import InfoHeirachy from '../components/landingpagesections/InfoHeirachy';
import Testimonials from '../components/landingpagesections/Testimonials';
import Cta from '../components/landingpagesections/Cta';
import Pricing from '../components/landingpagesections/Pricing';

const Landingpage = ({ showNav, showFooter }) => {
	const { user } = useAuth();

	if (user) {
	  return <Navigate to="/dashboard" replace />;
	}

	return (
		<div>
			{showNav && <Navbar />}
			<div className='px-[60px] pt-[50px] changa'>
				<Hero />
				<Secondsection />
				<Thirdsection />
				<InfoHeirachy />
				<Testimonials />
			</div>
			<div className='changa'>
				<Cta />
			</div>
			<Pricing />
			{showFooter && <Footer />}
		</div>
	);
};

export default Landingpage;
