import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const UserContext = createContext({});

export const UserProvider = ({ children }) => {
	const [user, setUser] = useState(() => {
		const storedUser = sessionStorage.getItem('user');
		return storedUser ? JSON.parse(storedUser) : {};
	});

	useEffect(() => {
		sessionStorage.setItem('user', JSON.stringify(user));
	}, [user]);

	return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};

export default UserContext;

UserProvider.propTypes = {
	children: PropTypes.element,
};