import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import logo from '../../assets/images/createaccountlogo.svg';
import successTick from '../../assets/images/success-tick.svg';
import balloneBig from '../../assets/images/Ellipse-1.svg';
import balloneSmall from '../../assets/images/Ellipse-2.svg';
import mobilelogo from '../../assets/images/logomobile.svg';

const PasswordSuccess = () => {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });
	const navigate = useNavigate();

	const loginHandler = () => {
		navigate('/login', {replace: true});
	};

	return (
		<div>
			{isTabletOrMobile ? (
				<div className='px-5 py-12 bg-white changa'>
					<div className='mb-10'>
						<img
							src={mobilelogo}
							alt=''
						/>
					</div>
					<div className='flex justify-center items-center'>
						<div>
							<div className='flex justify-center items-center mb-7'>
								<img
									className=''
									src={successTick}
									alt='Success Tick'
								/>
							</div>
							<div className='text-center'>
								<h2 className='mb-1 text-xl leading-[35px] changa-bold'>Successful password reset!</h2>
								<p className=' text-[#373737] text-base'>
									You can now use your new password to login to your account
								</p>
							</div>
							<div className='mt-16'>
								<button
									onClick={loginHandler}
									className='w-full h-[49px] bg-[#414080] text-[#FFFFFF] rounded-[8px]'>
									Login
								</button>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className='flex justify-center items-center min-h-screen bg-[#F8F9FC] changa'>
					<div className='mt-8'>
						<Link to='/'>
							<div className='mb-[28.63px]'>
								<img
									src={logo}
									alt=''
								/>
							</div>
						</Link>

						<div className='relative'>
							<div className='absolute z-10 -top-8 -right-16 sm:top-4 sm:-right-16'>
								<img
									className='w-56 h-56'
									src={balloneBig}
									alt='Ballone'
								/>
							</div>

							<div className='relative z-20 container mx-auto py-14 px-10 mb-14 bg-white rounded-lg shadow-[20px_40px_100px_rgba(148,148,148,0.1)]'>
								<div>
									<div className='mb-9 text-center'>
										<div className='flex justify-center mb-4'>
											<img
												className=''
												src={successTick}
												alt='Success Tick'
											/>
										</div>
										<h2 className='mb-1 text-[19px] leading-[35px] font-semibold'>Successful password reset!</h2>
										<p className='max-w-[240px] mx-auto text-[#373737] text-xs'>
											You can now use your new password to login to your account
										</p>
									</div>

									<button
										className='inputbox w-[417px] py-3 flex justify-center items-center space-x-4 mb-[29px] text-lg text-white bg-[#414080]'
										onClick={loginHandler}>
										Login
									</button>
								</div>
							</div>

							<div className='absolute z-10 -bottom-10 -left-20'>
								<img
									className='w-36 h-36'
									src={balloneSmall}
									alt='Ballone'
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default PasswordSuccess;
