import { Layout } from './layout';

const Security = () => {
	return (
		<Layout>
			<div className='px-5 md:px-14 py-9 md:py-10 bg-[#FAFBFD]'>
				<div>
					<h1 className='changa-bold text-5xl mb-5 md:mb-20 text-[#5655AA]'>Security</h1>
				</div>
				<p>
					HelpAI is committed to providing a secure and trustworthy platform for our users. We understand the
					importance of privacy and security, and we take the necessary measures to protect our users&apos;
					information and data.
				</p>
				<br />
				<p>
					We understand the importance of privacy and security, and we take the necessary measures to protect
					our users&apos; information and data. Our internal security practices are designed to ensure that
					our digital environment is secure and risk-free. We use the least privilege paradigm to control
					internal access to our facilities, systems, and resources. Our employees are required to use
					two-factor authentication (2FA) for additional security measures. At HelpAI, security is integrated
					into our development lifecycle to create better and safer products. Our applications and systems
					undergo extensive security design reviews and assessments, both internally and by reputable
					third-party security experts. We also perform regular security risk assessments to ensure that our
					security posture is up-to-date.
				</p>
				<br />
				<p>
					We have a dedicated team that tests our systems and workflows through offensive security testing.
					This team simulates real-world attacks to identify previously unknown vulnerabilities in our systems
					and to prioritize addressing areas of greatest risk.
				</p>
				<br />
				<p>
					We continuously monitor and investigate potential security threats and events, ensuring that we are
					alerted to and appropriately respond to any issues. We welcome input from the security research
					community and encourage responsible disclosure of any potential vulnerabilities to our Bug Bounty
					program. We are committed to addressing security issues responsibly and in a timely manner.
				</p>
				<br />
				<p>
					At HelpAI, we strive to maintain a secure and trusted platform for our users, and we are dedicated
					to keeping our security practices up-to-date with the latest industry standards and best practices.
				</p>
			</div>
		</Layout>
	);
};

export default Security;