import { atom } from 'recoil';

// const { persistAtom } = recoilPersist();

export const userName = atom({
  key: 'userName',
  default: "",
});

export const userEmail = atom({
  key: 'userEmail',
  default: "",
});

export const userPhoneNumber = atom({
  key: 'userPhoneNumber',
  default: "",
});

export const userPassword = atom({
  key: 'userPassword',
  default: "",
});