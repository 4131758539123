import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { RecoilRoot } from 'recoil';
import { ProfileProvider } from './context/ProfileContext';
import { UserProvider } from './context/UserContext';
import { FeedbackProvider } from './context/FeedbackContext';
import * as Sentry from "@sentry/react";

//import posthog from 'posthog-js';
//import { PostHogProvider } from 'posthog-js/react';
// posthog.init('phc_ayApVhDvvawWUezJ9Gs9BlDW97WH2QviANHW1BoNGpc', {
// 	api_host: 'https://app.posthog.com',
// });

Sentry.init({
  dsn: "https://44a4c614940dc4d43ac8942be0360d79@o4507695891808256.ingest.us.sentry.io/4507695894233088",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')).render(
	<React.StrictMode>
		{/* <PostHogProvider client={posthog}> */}
			<RecoilRoot>
				<FeedbackProvider>
					<UserProvider>
						<ProfileProvider>
							<App />
						</ProfileProvider>
					</UserProvider>
				</FeedbackProvider>
			</RecoilRoot>
		{/* </PostHogProvider> */}
	</React.StrictMode>
);
