import { NavLink, Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import rarr from '../../assets/images/Vector Right Arrow.svg';

const MobileNavbar = ({ showMobileNav }) => {
	return (
		<nav
			id='menu'
			className={`hide-nav absolute z-10 top-0 bottom-0 left-0 items-center justify-center w-full min-h-screen px-10 mt-12 text-xl leading-[33px] font-semibold bg-white changa ${
				!showMobileNav ? 'flex' : 'hidden'
			}`}>
			<ul className='w-full'>
				<ScrollLink
					to='top'
					smooth={true}
					duration={500}
					className='flex flex-col space-y-5 mb-5'>
					<li className='leading-[33px] hover:text-[#414080]'>Home</li>
					<div className='border-b-[0.7px] border-[#D5D5FF]' />
				</ScrollLink>
				<ScrollLink to='user-guide-section'
					smooth={true}
					duration={700}
					href='#user-guide-section'
					className='flex flex-col space-y-5 mb-5'>
					<li className='leading-[33px] hover:text-[#414080]'>User Guide</li>
					<div className='border-b-[0.7px] border-[#D5D5FF]' />
				</ScrollLink>
				<ScrollLink to='pricing'
					smooth={true}
					duration={500}
					className='flex flex-col space-y-5 mb-5'>
					<li className='leading-[33px] hover:text-[#414080]'>Pricing</li>
					<div className='border-b-[0.7px] border-[#D5D5FF]' />
				</ScrollLink>
        
				<div className='flex flex-col space-y-5 mb-7'>
					<Link to='/login'>
						<p className='text-[#414080]'>Log In</p>
					</Link>
					<div className='border-b-[0.7px] border-[#D5D5FF]' />
				</div>
				<div className='flex flex-col space-y-5 mb-5'>
					<Link
						to='/create-account'
						className='text-[#414080]'>
						Create Account
					</Link>
					<div className='border-b-[0.7px] border-[#D5D5FF]' />
				</div>
			</ul>
		</nav>
	);
};

export default MobileNavbar;
