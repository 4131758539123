import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import Cookies from "js-cookie";

import logo from "../../assets/images/createaccountlogo.svg";
import "../../assets/css/Userdomain.css";
import group63 from "../../assets/images/group63.svg";
import googlelogo from "../../assets/images/Google logo.svg";
import balloneBig from "../../assets/images/Ellipse-1.svg";
import balloneSmall from "../../assets/images/Ellipse-2.svg";
import mobilelogo from "../../assets/images/logomobile.svg";
import BASE_URL from "../../services/index";
import { useAuth } from "../../hooks/useAuthentication";
import eyeclose from "../../assets/images/eye-hide.svg";
import eyeopen from "../../assets/images/eye-open.svg";
import useUser from "../../hooks/useUser";

const endpoint = "/auth/login";

const Label = ({ html, title }) => {
  return (
    <label
      className="font-[400] text-[14.3883px] leading-[26px] tracking-[0.025em] text-[#1A1A33]"
      htmlFor={html}
    >
      {title}
    </label>
  );
};

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { login } = useAuth();
  const { user, setUser } = useUser();
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const location = useLocation();
  const [isProcessingGoogleAuth, setIsProcessingGoogleAuth] = useState(false);

  // console.log(user);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem("userEmail");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  // console.log(`${BASE_URL}${endpoint}`);
  const login_url = `${BASE_URL}${endpoint}`;

  useEffect(() => {
    // Check if there's a code parameter in the URL
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");

    if (code) {
      handleGoogleCallback(code);
    }
  }, [location]);

  const handleGoogleCallback = async (code) => {
    setIsProcessingGoogleAuth(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/auth/google/callback?code=${code}`
      );
      console.log("Response from Google callback:", response);

      if (response.data.success) {
        const res = response?.data?.data;
        console.log("Data received:", res);

        const isNewUser = !!res?.newUser;
        const user = res?.user || res?.newUser;

        if (!user) {
          // throw new Error("No user data found in response");
          console.log("No user object found");
        }

        // Store the access token, user data, and email in local storage
        const loginTime = new Date().getTime();
        localStorage.setItem("responseData", JSON.stringify(res?.accessToken));
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("userEmail", user?.email);
        localStorage.setItem("loginTime", loginTime);

        // Create user object with the same structure as email/password login
        const userData = {
          token: res?.accessToken,
          id: user?._id,
          name: user?.fullName,
          useremail: user?.email,
          phone: user?.phoneNumber,
          dp: user?.profileImage,
          tier: user?.tier,
          emailVerified: user?.emailVerified,
          pageNumber: user?.lastViewedProfile?.pageNumber,
          isQuestionAnswered: user?.isQuestionAnswered,
          lastRequestTime: user?.rateLimitInfo?.lastRequestTime,
          referralLink: user?.referralLink,
          completeTour: user?.completeTour,
        };

        // Data sent directly to session storage
        sessionStorage.setItem("user", JSON.stringify(userData));
        sessionStorage.setItem("loginEmail", user?.email);
        setUser(userData);

        // Delay to ensure local storage completes before redirecting
        await new Promise((resolve) => setTimeout(resolve, 200));

        // Navigate based on whether this is a new user
        if (isNewUser) {
          navigate("/questions", { replace: true });
        } else {
          navigate("/dashboard", { replace: true });
        }
        window.location.reload();
      } else {
        setError("Authentication failed");
      }
    } catch (error) {
      console.error("Google callback error:", error);
      setError(error.response?.data?.Message || "Authentication failed");
    } finally {
      setIsProcessingGoogleAuth(false);
    }
  };

  // If processing Google auth, show loading screen
  if (isProcessingGoogleAuth) {
    return (
      <div className="h-screen w-full flex flex-col items-center justify-center bg-[#F8F9FC]">
        <div className="w-20 h-20 border-4 border-t-transparent border-[#414080] rounded-full animate-spin mb-4"></div>
        <h2 className="text-xl font-medium text-[#414080] mb-2">
          Completing Sign In
        </h2>
        <p className="text-gray-500">
          Please wait while we authenticate your account...
        </p>
      </div>
    );
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    setIsSubmitting(true);
    const data = {
      email,
      password,
    };
    axios
      .post(login_url, data, { withCredentials: true })
      .then((response) => {
        login(response?.data?.data?.accessToken);
        sessionStorage.setItem("loginEmail", email);
        // console.log(response.data);
        const token = response?.data?.data?.accessToken;
        const id = response?.data?.data?.user?._id;
        const name = response?.data?.data?.user?.fullName;
        const useremail = response?.data?.data?.user?.email;
        const phone = response?.data?.data?.user?.phoneNumber;
        const dp = response?.data?.data?.user?.profileImage;
        const tier = response?.data?.data?.user?.tier;
        const isQuestionAnswered =
          response?.data?.data?.user?.isQuestionAnswered;
        const emailVerified = response?.data?.data?.user?.emailVerified;
        const pageNumber =
          response?.data?.data?.user?.lastViewedProfile?.pageNumber;
        const lastRequestTime =
          response?.data?.data?.user?.rateLimitInfo?.lastRequestTime;
        const referralLink = response?.data?.data?.user?.referralLink;
        const completeTour = response?.data?.data?.user?.completeTour;
        setUser({
          token,
          id,
          name,
          useremail,
          phone,
          dp,
          tier,
          emailVerified,
          pageNumber,
          isQuestionAnswered,
          lastRequestTime,
          referralLink,
          completeTour,
        });
        // console.log(response?.data);
        setEmail("");
        setPassword("");
        setIsSubmitting(false);
        navigate("/dashboard", { replace: true });
      })
      .catch((error) => {
        console.error(`Error encountered loggin in; ` + error);
        setError(error?.response?.data?.Message);
        setIsSubmitting(false);
        // setEmail('');
        // setPassword('');
        setTimeout(() => {
          setError("");
        }, 10500);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  useEffect(() => {
    setIsFormValid(email !== "" && password !== "");
  }, [email, password]);

  const handleGoogleAuth = async () => {
    setIsGoogleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/auth/google`);
      // const response = await axios.get("https://api.helpai.co/auth/google")
      // Handle successful response
      if (response.data) {
        setIsGoogleLoading(false);
        // You might want to handle the response data here
        console.log("Google auth response:", response.data);
        // Potentially redirect or handle the authentication response

        if (response.data.redirectGoogleURL) {
          window.location.href = response.data.redirectGoogleURL;
        }
      }
    } catch (error) {
      setIsGoogleLoading(false);
      console.error("Google authentication error:", error);
      setError(error.response?.data?.Message || "Google authentication failed");
      setTimeout(() => {
        setError("");
      }, 10500);
    }
  };

  const GoogleButton = ({ fullWidth = false }) => (
    <button
      onClick={handleGoogleAuth}
      disabled={isGoogleLoading}
      className={clsx([
        fullWidth ? "w-full" : "w-[419px]",
        "h-[49px] flex justify-center items-center gap-[10px] mb-[29px] inputbox",
        isGoogleLoading && "cursor-not-allowed opacity-75",
      ])}
    >
      {isGoogleLoading ? (
        <div className="flex items-center gap-2">
          <div className="w-5 h-5 border-2 border-t-transparent border-gray-600 rounded-full animate-spin"></div>
          <span>Connecting to Google...</span>
        </div>
      ) : (
        <>
          <div>
            <img src={googlelogo} alt="Google Logo" />
          </div>
          <div>
            <p>Sign in with Google</p>
          </div>
        </>
      )}
    </button>
  );

  return (
    <>
      {isTabletOrMobile ? (
        <div className="bg-white py-12 px-4 changa">
          <div className="mb-4">
            <img src={mobilelogo} alt="" />
          </div>
          <div className="mb-5">
            <h1 className="changa-bold text-lg text-[#414080]">
              Log in to your HelpAI account
            </h1>
          </div>
          <form className="mb-6" onSubmit={handleSubmit}>
            <Label html="Email Address" title="Email Address" />
            <input
              required
              type="email"
              name="email"
              value={email}
              onChange={handleInputChange}
              className="bg-white rounded-lg border border-1 border-[#CFCFCF] outline-none w-full px-6 py-3 mb-5"
              placeholder="ex. johndoe@gmail.com"
            />
            <Label html="Password" title="Password" />
            <div className="relative">
              <input
                id="password"
                required
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full rounded-[8px] border border-1 border-[#CFCFCF] p-3 mt-[8px] mb-14 outline-none"
              />
              <div
                className="absolute top-5 right-2 transition-all duration-300 text-gray-500"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <img src={eyeclose} alt="" />
                ) : (
                  <img src={eyeopen} alt="" />
                )}
              </div>
            </div>

            <div className="mb-5 cursor-pointer">
              <Link to="/forgot-password">
                <p className="text-[#414080] text-sm">Forgot Password?</p>
              </Link>
            </div>

            <button
              className={clsx([
                "w-full h-[49px] bg-[#414080] text-[#FFFFFF] rounded-[8px]",
                {
                  "cursor-not-allowed opacity-50": !isFormValid || isSubmitting,
                },
              ])}
              disabled={!isFormValid || isSubmitting}
            >
              {isSubmitting ? "Checking details, please wait..." : "Continue"}
            </button>
          </form>
          <div className="flex justify-center items-center mb-[41px]">
            <img src={group63} alt="" />
          </div>
          {/* <a href="https://api.helpai.co/auth/google/sign-in"> */}
          <GoogleButton fullWidth />
          {/* </a> */}
          <div className="flex justify-center items-center">
            <p>
              Don't have an account?{" "}
              <Link to="/create-account">
                <span className="text-[#5655AA]">Create an account</span>
              </Link>
            </p>
          </div>
          <AnimatePresence>
            {error && (
              <motion.div
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "100%" }}
                transition={{ duration: 0.3 }}
                className="text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg"
              >
                <p>{error}</p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ) : (
        <div className="bg-[#F8F9FC] h-screen flex justify-center text-start relative changa">
          <div className="mt-[65px]">
            {/* logo */}
            <Link to="/">
              <div className="mb-[28.63px]">
                <img src={logo} alt="" />
              </div>
            </Link>
            {/* form */}
            <div className="relative">
              <div className="absolute z-10 -top-8 -right-16 sm:top-4 sm:-right-16">
                <img className="w-56 h-56" src={balloneBig} alt="Ballone" />
              </div>

              <div
                className={clsx([
                  "relative z-20 w-[498px] h-[710px] bg-[#FFFFFF] rounded-lg form px-[40px] pt-[40px] shadow-[20px_40px_100px_rgba(148,148,148,0.1)]",
                ])}
              >
                <div className="mb-10">
                  <h2 className="font-semibold text-2xl leading-[0.02em] text-[#414080]">
                    Log in to your HelpAI account
                  </h2>
                </div>
                {/* form section */}
                <form onSubmit={handleSubmit} className="mb-[25px]">
                  <Label html="Email" title="Email Address" />
                  <input
                    required
                    type="text"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                    className={clsx([
                      "w-[417px] h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px]",
                    ])}
                    placeholder="ex. johndoe@gmail.com"
                  />
                  {/*  */}
                  <div>
                    <Label html="password" title="Password" />
                    <div className="relative">
                      <input
                        id="password"
                        required
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="newinput outline-none border  w-[417px] h-[52px] rounded-[8px] inputbox p-3 mt-[8px] border-gray-400"
                      />

                      <div
                        className="absolute right-3 top-5 transition-all duration-300 text-gray-500"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <img src={eyeclose} alt="" />
                        ) : (
                          <img src={eyeopen} alt="" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 mb-5 cursor-pointer">
                    <Link to="/forgot-password">
                      <p className="text-[#414080] text-sm">Forgot Password?</p>
                    </Link>
                  </div>
                  {/* button */}
                  <button
                    type="submit"
                    className={clsx([
                      "w-[419px] h-[49px] bg-[#414080] text-[#FFFFFF] rounded-[8px]",
                      {
                        "cursor-not-allowed opacity-50":
                          !isFormValid || isSubmitting,
                      },
                    ])}
                    disabled={!isFormValid || isSubmitting}
                  >
                    {isSubmitting
                      ? "Checking details, please wait..."
                      : "Continue"}
                  </button>
                </form>
                <div className="flex justify-center items-center mb-[41px]">
                  <img src={group63} alt="" />
                </div>
                {/* <a href="https://api.helpai.co/auth/google/sign-in"> */}
                <GoogleButton fullWidth />
                {/* </a> */}
                <div className="flex justify-center space-x-1 text-[14.3883px] leading-[26px]">
                  <p>Don't have an account? </p>
                  <Link
                    to="/create-account"
                    className="text-[#5655AA] font-semibold"
                  >
                    Sign up
                  </Link>
                </div>
              </div>

              <div className="absolute z-10 -bottom-10 -left-20">
                <img className="w-36 h-36" src={balloneSmall} alt="Ballone" />
              </div>
            </div>
          </div>
          <AnimatePresence>
            {error && (
              <motion.div
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "100%" }}
                transition={{ duration: 0.3 }}
                className="text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg"
              >
                <p>{error}</p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    </>
  );
};

export default Login;
