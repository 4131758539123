import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import rarr from '../../assets/images/Vector Right Arrow.svg';
import tick from '../../assets/images/Vector Check.svg';
import wrong from '../../assets/images/Vector Wrong.svg';

const Pricing = () => {
	const navigate = useNavigate();
	return (
		<section
			id='pricing'
			className='pt-24 pb-16 changa'>
			<div className='text-center mb-16'>
				<h3 className='changa-bold mb-[6px] text-2xl leading-[44px] max-w-xs mx-auto sm:max-w-none lg:text-[32px] lg:leading-[59px]'>
					Choose the perfect plan for your needs
				</h3>
				<p className='text-lg leading-[33px]'>Affordable pricing to suit any budget</p>
			</div>

			<div className='flex flex-col space-y-6 md:flex-row md:space-y-0 md:space-x-6 md:justify-center'>
				<div className='flex justify-center'>
					<div className='flex flex-col items-center bg-white py-10 px-8 rounded-[10px] shadow-[-10px_-10px_40px_rgba(218,218,218,0.5)] md:px-10'>
						<h4 className='mb-[6px] changa-bold text-2xl leading-[44px] md:text-[32px] md:leading-[59px]'>
							Free
						</h4>
						<p className='mb-4 text-3xl font-bold md:mb-6 md:text-4xl'>
							<span className='changa-bold text-[40px] leading-[74px] text-[#8180FF] md:text-5xl md:leading-[88px]'>
								$0
							</span>
							/mo
						</p>
						<div className='hidden flex-col space-y-2 mb-16 text-lg md:flex'>
							<div className='flex items-center space-x-4'>
								<img
									src={tick}
									alt='Vector Check'
								/>
								<span className='text-[#1A1A1A]'>View 26 co-founders daily</span>
							</div>
							<div className='flex items-center space-x-4'>
								<img
									src={tick}
									alt='Vector Check'
								/>
								<span className='text-[#1A1A1A]'>Message your matches</span>
							</div>
							<div className='flex items-center space-x-4'>
								<img
									src={tick}
									alt='Vector Check'
								/>
								<span className='text-[#1A1A1A]'>2 rewinds daily</span>
							</div>
							<div className='flex items-center space-x-4'>
								<img
									src={tick}
									alt='Vector Check'
								/>
								<span className='text-[#1A1A1A]'>Unlimited dislike</span>
							</div>
							<div className='flex items-center space-x-4'>
								<img
									src={wrong}
									alt='Vector Check'
								/>
								<span className='text-[#878787]'>See who likes your profile</span>
							</div>
							<div className='flex items-center space-x-4'>
								<img
									src={wrong}
									alt='Vector Check'
								/>
								<span className='text-[#878787]'>View co-founder's full details</span>
							</div>
						</div>
						<button
							onClick={() => {
								navigate('/create-account');
							}}
							className='w-[288px] h-[57px] mb-7 rounded-[6px] hover:bg-[#6C6BD5] hover:text-white transition-all duration-300 border-[2px] border-[#6C6BD5]'>
							Get Started
						</button>
						<Link
							to='/'
							className='text-[#414080] flex items-center space-x-4 md:hidden'>
							<p>See all features</p>
							<img
								src={rarr}
								alt='Right arrow'
							/>
						</Link>
					</div>
				</div>

				<div className='flex justify-center'>
					<div className='flex flex-col items-center bg-white py-10 px-8 rounded-[10px] shadow-[-10px_-10px_40px_rgba(218,218,218,0.5)] md:px-10'>
						<h4 className='mb-[6px] changa-bold text-2xl leading-[44px] md:text-[32px] md:leading-[59px]'>
							Standard
						</h4>
						<p className='mb-4 text-3xl font-bold md:mb-6 md:text-4xl'>
							<span className='changa-bold text-[40px] leading-[74px] text-[#8180FF] md:text-5xl md:leading-[88px]'>
								$9
							</span>
							/mo
						</p>
						<div className='hidden flex-col space-y-2 mb-16 text-lg md:flex'>
							<div className='flex items-center space-x-4'>
								<img
									src={tick}
									alt='Vector Check'
								/>
								<span className='text-[#1A1A1A]'>View unlimited co-founders daily</span>
							</div>
							<div className='flex items-center space-x-4'>
								<img
									src={tick}
									alt='Vector Check'
								/>
								<span className='text-[#1A1A1A]'>Message unmatched co-founders</span>
							</div>
							<div className='flex items-center space-x-4'>
								<img
									src={tick}
									alt='Vector Check'
								/>
								<span className='text-[#1A1A1A]'>Unlimited rewinds</span>
							</div>
							<div className='flex items-center space-x-4'>
								<img
									src={tick}
									alt='Vector Check'
								/>
								<span className='text-[#1A1A1A]'>Unlimited dislikes</span>
							</div>
							<div className='flex items-center space-x-4'>
								<img
									src={tick}
									alt='Vector Check'
								/>
								<span className='text-[#1A1A1A]'>See who likes your profile</span>
							</div>
							<div className='flex items-center space-x-4'>
								<img
									src={tick}
									alt='Vector Check'
								/>
								<span className='text-[#1A1A1A]'>View co-founder's full details</span>
							</div>
						</div>
						<button
							onClick={() => {
								navigate('/create-account');
							}}
							className='w-[288px] h-[57px] mb-7 rounded-[6px] hover:bg-[#6C6BD5] hover:text-white transition-all duration-300 bg-[#6C6BD5] text-white border-[2px] border-[#6C6BD5] hover:opacity-90'>
							Choose Plan
						</button>
						<Link
							to='/'
							className='text-[#414080] flex items-center space-x-4 md:hidden'>
							<p>See all features</p>
							<img
								src={rarr}
								alt='Right arrow'
							/>
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Pricing;
