import { Link } from 'react-router-dom';

const Footer = () => {
	const currentYear = new Date().getFullYear();
	return (
		<section className='bg-white py-14 px-[60px] lg:py-20 changa'>
			<div className='flex flex-col space-y-10 mb-16 lg:flex-row lg:justify-between lg:space-y-0 lg:space-x-24 lg:mb-20'>
				<div className='text-center lg:text-left'>
					<h4 className='changa-bold text-2xl leading-[44px] mb-2'>
						Find your <span className='text-[#5655AA]'>perfect co-founder</span>
					</h4>
					<p className='text-lg max-w-xs mx-auto'>
						We make searching and connecting with the right partners easier for you.
					</p>
				</div>

				<div className='flex flex-col space-y-8 justify-between sm:flex-row sm:space-y-0 lg:space-x-36'>
					<div className='flex justify-between sm:space-x-8 md:space-x-24 lg:space-x-32'>
						<div>
							<h4 className='font-semibold text-2xl leading-[44px] mb-4'>About</h4>
							<div className='flex flex-col space-y-4 text-lg'>
								<Link to='/'>About HelpAI</Link>
								<Link to='/'>Contact Us</Link>
								<Link
									to='/security'
									className='cursor-pointer'>
									Security
								</Link>
								<Link
									to='/privacy'
									className='cursor-pointer'>
									Privacy Policy
								</Link>
								<Link
									to='/terms'
									className='cursor-pointer'>
									Terms and Conditions
								</Link>
							</div>
						</div>

						<div>
							<h4 className='font-semibold text-2xl leading-[44px] mb-4'>Get Started</h4>
							<div className='flex flex-col space-y-4 text-lg'>
								<Link to='/create-account'>Create Account</Link>
								<Link to='/login'>Sign In</Link>
							</div>
						</div>
					</div>

					<div>
						<h4 className='font-semibold text-2xl leading-[44px] mb-4'>Follow Us</h4>
						<div className='flex flex-col space-y-4 text-lg'>
							<a
								className='cursor-pointer'
								href='https://linkedin.com/company/helpaidotco'
								target='_blank'
								rel='noopener noreferrer'>
								LinkedIn
							</a>
							<a
								className='cursor-pointer'
								href='https://facebook.com/helpaidotco'
								target='_blank'
								rel='noopener noreferrer'>
								Facebook
							</a>
							<a
								className='cursor-pointer'
								href='https://instagram.com/helpaidotco'
								target='_blank'
								rel='noopener noreferrer'>
								Instagram
							</a>
							<a
								className='cursor-pointer'
								href='https://twitter.com/helpaidotco'
								target='_blank'
								rel='noopener noreferrer'>
								Twitter
							</a>
						</div>
					</div>
				</div>
			</div>

			<div>
				<p className='text-lg text-[#424242]'>Copyright HelpAI {currentYear}</p>
			</div>
		</section>
	);
};

export default Footer;
