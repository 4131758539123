import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import { useRecoilState } from "recoil";

import logo from "../../assets/images/createaccountlogo.svg";
import "../../assets/css/Userdomain.css";
import group63 from "../../assets/images/group63.svg";
import googlelogo from "../../assets/images/Google logo.svg";
import balloneBig from "../../assets/images/Ellipse-1.svg";
import balloneSmall from "../../assets/images/Ellipse-2.svg";
import mobilelogo from "../../assets/images/logomobile.svg";
import BASE_URL from "../../services/index";
import {
  userName,
  userEmail,
  userPhoneNumber,
  userPassword,
} from "../../atoms/authatoms/authAtoms";
import { useProfileData } from "../../services/getProfileService";

const Label = ({ html, title }) => {
  return (
    <label
      className="font-[400] text-[14.3883px] leading-[26px] tracking-[0.025em] text-[#1A1A33]"
      htmlFor={html}
    >
      {title}
    </label>
  );
};

const EditPersonalDetails = () => {
  const [fullName, setFullName] = useRecoilState(userName);
  const [phoneNumber, setPhoneNumber] = useRecoilState(userPhoneNumber);
  const [userLocation, setUserLocation] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [response, setResponse] = useState();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { profileData } = useProfileData();

  const endpoint = "/account/settings/edit-profile";

  const getAuthTokenFromLocalDb = () => {
    const token = localStorage.getItem("responseData");
    return token;
    console.log(token);
  };
  // console.log(profileData)
  useEffect(() => {
    if (profileData) {
      setFullName(profileData.data.fullName);
      setPhoneNumber(profileData.data.phoneNumber);
      setUserLocation(profileData.data.userLocation);
    }
  }, [profileData]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);

    const authToken = getAuthTokenFromLocalDb();

    if (!authToken) {
      setError("Authorization token not found.");
      setIsSubmitting(false);
      return;
    }

    const data = {
      fullName,
      userLocation,
      phoneNumber,
    };

    console.log({
      fullName,
      userLocation,
      phoneNumber,
    });

    try {
      const response = await axios.post(BASE_URL + endpoint, data, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      console.log(response.data.message);
      setFullName("");
      setUserLocation("");
      setPhoneNumber("");
      setIsSubmitting(false);
      setTimeout(() => {
        navigate("/settings", { replace: true });
      }, 5000);
    } catch (error) {
      setError(error.response.data.Message);
      setIsSubmitting(false);
      setTimeout(() => {
        setError("");
      }, 10500);
    }
  };

  const isFormValid = fullName && phoneNumber && userLocation;

  return (
    <>
      {isTabletOrMobile ? (
        <>
          <div className="bg-white py-12 px-4 changa">
            <div className="mb-4">
              <Link to="/settings">
                <h1 className="text-xl text-[#5655AA]">Back</h1>
              </Link>
            </div>
            <div className="mb-5">
              <h1 className="changa-bold text-lg">
                Edit your Personal details
              </h1>
            </div>
            <div>
              <form className="mb-6" onSubmit={handleSubmit}>
                <Label html="Full Name" title="Full Name" />
                <input
                  required
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="bg-white rounded-lg border border-1 border-[#CFCFCF] outline-none w-full px-6 py-3 mb-5"
                  placeholder="ex. John Doe"
                />
                <Label html="Phone Number" title="Phone Number" />
                <input
                  required
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="bg-white rounded-lg border border-1 border-[#CFCFCF] outline-none w-full px-6 py-3 mb-5"
                  placeholder="ex. (123)801-111-5000"
                />
                <Label html="Location" title="Location" />
                <input
                  required
                  type="text"
                  value={userLocation}
                  onChange={(e) => setUserLocation(e.target.value)}
                  placeholder="London, United Kingdom"
                  className="bg-white rounded-lg border border-1 border-[#CFCFCF] outline-none w-full px-6 py-3 mb-5"
                />
                <button
                  type="submit"
                  className={`${
                    isFormValid || isSubmitting
                      ? "w-full h-[49px] bg-[#414080] text-[#FFFFFF] rounded-[8px]"
                      : "w-full h-[49px] bg-[#414080] text-[#FFFFFF] rounded-[8px] cursor-not-allowed opacity-50"
                  }`}
                  disabled={!isFormValid || isSubmitting}
                >
                  {isSubmitting ? "Processing, please wait..." : "Continue"}
                </button>
              </form>
              <div className="flex justify-center items-center mb-[41px]">
                <img src={group63} alt="" />
              </div>
              <button
                className={clsx([
                  "inputbox w-full h-[49px] flex justify-center items-center gap-[10px] mb-14 blur",
                ])}
              >
                <div>
                  <img src={googlelogo} alt="Google Logo" />
                </div>
                <div>
                  <p>Sign up with Google</p>
                </div>
              </button>
              <div className="flex justify-center items-center">
                <p>
                  Already have an account?{" "}
                  <Link to="/login">
                    <span className="text-[#5655AA]">Login</span>
                  </Link>
                </p>
              </div>
            </div>
            {/*  */}
            <AnimatePresence>
              {error && (
                <motion.div
                  initial={{ opacity: 0, x: "100%" }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: "100%" }}
                  transition={{ duration: 0.5 }}
                  className="text-white text-xl px-[21px] py-4 absolute top-7 right-2 bg-[#5655AA] flex justify-center items-center rounded-lg"
                >
                  <p>{error}</p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </>
      ) : (
        <div className="bg-[#F8F9FC] h-screen flex justify-center text-start changa overflow-y-auto">
          <div className="mt-[65px]">
            <Link to="/settings">
              <div className="mb-[28.63px]">
                <h1 className="text-[#6C6BD4] text-2xl">Back</h1>
              </div>
            </Link>

            <div className="relative">
              <div className="absolute z-10 -top-8 -right-16 sm:top-4 sm:-right-16">
                <img className="w-56 h-56" src={balloneBig} alt="Ballone" />
              </div>

              <div className="relative z-10 w-[498px] h-[620px] bg-[#FFFFFF] rounded-lg form px-[40px] pt-[40px] shadow-[20px_40px_100px_rgba(148,148,148,0.1)]">
                <div className="mb-10">
                  <h2 className="font-semibold text-[19.106px] leading-[0.02em] text-[#1A1A33]">
                    Edit your personal information
                  </h2>
                </div>

                <form onSubmit={handleSubmit} className="mb-[25px]">
                  <div className="flex flex-col">
                    <Label html="fullName" title="FullName" />
                    <input
                      type="text"
                      id="fullName"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      placeholder="ex. John Doe"
                      className={clsx([
                        "relative w-[417px] h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-5 border-[1.03915px] border-[#AFAFAF]",
                      ])}
                    />
                  </div>

                  <div className="flex flex-col">
                    <Label html="phone" title="Phone Number" />
                    <input
                      type="tel"
                      id="phone"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      placeholder="ex. (123)801-111-5000"
                      className={clsx([
                        "relative w-[417px] h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-5 border-[1.03915px] border-[#AFAFAF]",
                      ])}
                    />
                  </div>

                  <div className="flex flex-col">
                    <Label html="Location" title="Location" />
                    <input
                      type="text"
                      id="Location"
                      value={userLocation}
                      onChange={(e) => setUserLocation(e.target.value)}
                      placeholder="London, United Kingdom"
                      className={clsx([
                        "relative w-[417px] h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-20 border-[1.03915px] border-[#AFAFAF]",
                      ])}
                    />
                  </div>

                  <button
                    type="submit"
                    className={clsx([
                      "w-[419px] h-[49px] bg-[#414080] text-[#FFFFFF] rounded-[8px]",
                      {
                        "cursor-not-allowed opacity-50": isSubmitting,
                      },
                    ])}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Changing..." : "Save changes"}
                  </button>
                </form>
              </div>

              <div className="absolute z-5 -bottom-10 -left-20 sm:-bottom-8">
                <img className="w-36 h-36" src={balloneSmall} alt="Ballone" />
              </div>
            </div>
          </div>
          {/*  */}
          <AnimatePresence>
            {error && (
              <motion.div
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "100%" }}
                transition={{ duration: 0.5 }}
                className="text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg z-10"
              >
                <p>{error}</p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    </>
  );
};

export default EditPersonalDetails;
