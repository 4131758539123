import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';

import logo from '../../assets/images/createaccountlogo.svg';
import balloneBig from '../../assets/images/Ellipse-1.svg';
import balloneSmall from '../../assets/images/Ellipse-2.svg';
import mobilelogo from '../../assets/images/logomobile.svg';
import BASE_URL from '../../services/index';

const endpoint = '/auth/verify-otp';

const GetOtp = () => {
	const navigate = useNavigate();
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });
	const [otpCode, setOtpCode] = useState('');
	const [isFormValid, setIsFormValid] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [response, setResponse] = useState('');
	const [error, setError] = useState('');
	const email = sessionStorage.getItem('forgotPasswordEmail');
	// console.log(email);

	const submitOTPHandler = (e) => {
		e.preventDefault();

		setIsSubmitting(true);
		const data = {
			email,
			otpCode,
		};
		axios
			.post(BASE_URL + endpoint, data)
			.then((response) => {
				console.log(response.data);
				setOtpCode('');
				setIsSubmitting(false);
				setTimeout(() => {
					navigate('/reset-password', { replace: true });
				}, 1000);
			})
			.catch((error) => {
				console.error(error);
				setError("Unexpected error occured. Try again.");
				setIsSubmitting(false);
				// setOtpCode('');
				setTimeout(() => {
					setError('');
				}, 10500);
			});
	};

	useEffect(() => {
		setIsFormValid(otpCode !== '');
	}, [otpCode]);

	return (
		<>
			{isTabletOrMobile ? (
				<div className='px-5 py-12 bg-white changa'>
					<div className='mb-10'>
						<img
							src={mobilelogo}
							alt=''
						/>
					</div>
					<div className='mb-9'>
						<h2 className='mb-1 text-[19px] leading-[35px] changa-bold'>Enter OTP</h2>
						<p className='max-w-md text-[#373737]'>Enter six digit code that has been sent to you</p>
					</div>
					<div>
						<form onSubmit={submitOTPHandler}>
							<div className='flex flex-col mb-3'>
								<label
									className='font-[400] text-[14.3883px] leading-[26px] tracking-[0.025em] text-[#1A1A33]'
									htmlFor='email-phone'>
									OTP
								</label>
								<input
									type='text'
									value={otpCode}
									onChange={(e) => setOtpCode(e.target.value)}
									id='OTP'
									className=' h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px]'
								/>
							</div>
							<button
								className={clsx([
									'w-full h-[49px] bg-[#414080] text-[#FFFFFF] rounded-[8px]',
									{
										'cursor-not-allowed opacity-50': !isFormValid || isSubmitting,
									},
								])}
								disabled={!isFormValid || isSubmitting}>
								{isSubmitting ? 'Sending...' : 'Continue'}
							</button>
						</form>
					</div>
					<div>
						<div className='flex justify-center items-center mt-4'>
							<p>
								Already have an account?{' '}
								<Link to='/login'>
									<span className='text-[#5655AA]'>Login</span>
								</Link>
							</p>
						</div>
					</div>
					<AnimatePresence>
						{response && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg'>
								<p>{response}</p>
							</motion.div>
						)}
					</AnimatePresence>
					{/*  */}
					<AnimatePresence>
						{error && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg'>
								<p>{error}</p>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			) : (
				<div className='flex justify-center items-center min-h-screen bg-[#F8F9FC] changa'>
					<div className='mt-8'>
						<Link to='/'>
							<div className='mb-[28.63px]'>
								<img
									src={logo}
									alt=''
								/>
							</div>
						</Link>

						<div className='relative'>
							<div className='absolute z-10 -top-8 -right-16 sm:top-4 sm:-right-16'>
								<img
									className='w-56 h-56'
									src={balloneBig}
									alt='Ballone'
								/>
							</div>

							<div className='relative z-20 container mx-auto py-14 px-10 mb-14 bg-white rounded-lg shadow-[20px_40px_100px_rgba(148,148,148,0.1)]'>
								<div>
									<div className='mb-9'>
										<h2 className='mb-1 text-[19px] leading-[35px] changa-bold'>Enter OTP</h2>
										<p className='max-w-md text-[#373737] text-xs'>Enter six digit code that has been sent to you</p>
									</div>

									<form onSubmit={submitOTPHandler}>
										<div className='flex flex-col mb-3'>
											<label
												className='font-[400] text-[14.3883px] leading-[26px] tracking-[0.025em] text-[#1A1A33]'
												htmlFor='otp'>
												OTP
											</label>
											<input
												type='text'
												id='OTP'
												value={otpCode}
												onChange={(e) => setOtpCode(e.target.value)}
												placeholder='984 902'
												className='relative w-[417px] h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px]'
											/>
										</div>
										<button
											type='submit'
											className={clsx([
												'w-full h-[49px] bg-[#414080] text-[#FFFFFF] rounded-[8px]',
												{
													'cursor-not-allowed opacity-50': !isFormValid || isSubmitting,
												},
											])}
											disabled={!isFormValid || isSubmitting}>
											{isSubmitting ? 'Sending...' : 'Continue'}
										</button>
									</form>

									<div className='flex justify-center space-x-2 mb-10 text-sm leading-[26px] tracking-[.0.25em] mt-4'>
										<p className='text-[#5F5F5F]'>Don't have an account? </p>
										<Link
											to='/create-account'
											className='text-[#5655AA] font-semibold'>
											Sign up
										</Link>
									</div>
								</div>
							</div>

							<div className='absolute z-10 -bottom-10 -left-20'>
								<img
									className='w-36 h-36'
									src={balloneSmall}
									alt='Ballone'
								/>
							</div>
						</div>
					</div>
					<AnimatePresence>
						{response && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg'>
								<p>{response}</p>
							</motion.div>
						)}
					</AnimatePresence>
					{/*  */}
					<AnimatePresence>
						{error && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg'>
								<p>{error}</p>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			)}
		</>
	);
};

export default GetOtp;
