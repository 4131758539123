import React from "react";
import clsx from "clsx";

import fivestars from "../../assets/images/fivestars.svg";
import ellipse20 from "../../assets/images/Ellipse 20.svg";
import ellipse21 from "../../assets/images/Ellipse 21.svg";
import lawal from "../../assets/images/lawal.png";
import kemi from "../../assets/images/kemi.png";

const Testimonials = () => {
  return (
    <div className="mb-16">
      <div className="mb-[75px]">
        <div className="flex justify-center items-center">
          <h2 className="changa-bold font-[500] text-[24px] leading-[59px] text-[#1A1A1A] lg:text-[32px]">
            Don't just take our word for it
          </h2>
        </div>
        <div className="flex justify-center items-center ">
          <h2 className="changa-normal leading-[33px] text-[#1A1A1A]">
            Here's what some of our clients have to say
          </h2>
        </div>
      </div>
      {/*  */}
      {/* <div className="grid grid-cols-1 gap-4 mb-[166px] lg:grid-cols-3">
        <div
          className={clsx(["bg-white topcardshadow rounded-[5px] px-8 py-10"])}
        >
          <div className="mb-[29px]">
            <p className="leading-[29px]">
              We were matched on the platform some months ago and have been
              beyond pleased with th results. Finding a suitable partner to
              assist me with my startup would have been really difficult without
              HelpAI.
            </p>
          </div>

          <div className="mb-[34px]">
            <img src={fivestars} alt="stars" />
          </div>
          <div className="flex items-center space-x-3">
            <div className="flex">
              <img src={ellipse20} alt="ellipse 20" />
              <img src={ellipse21} alt="ellipse 21" />
            </div>
            <span className="changa-normal font-medium text-lg leading-[33px]">
              - Kate and Efe
            </span>
          </div>
        </div>
        <div
          className={clsx(["bg-white topcardshadow rounded-[5px] px-8 py-10"])}
        >
          <div className="mb-[29px]">
            <p className="leading-[29px]">
              I stumbled upon HelpAI not too long ago, and voila! I found the
              perfect co-founder for my project. It's been smooth sailing since
              then. I highly recommend this platform for anyone in need of a
              business partner!
            </p>
          </div>

          <div className="mb-[34px]">
            <img src={fivestars} alt="stars" />
          </div>
          <div className="flex items-center space-x-3">
            <div className="flex">
              <img src={lawal} alt="ellipse 20" />
            </div>
            <span className="changa-normal font-medium text-lg leading-[33px]">
              - Ahmed Lawal
            </span>
          </div>
        </div>
        <div
          className={clsx(["bg-white topcardshadow rounded-[5px] px-8 py-10"])}
        >
          <div className="mb-[29px]">
            <p className="leading-[29px]">
              I took a chance on HelpAI for my startup needs, and I couldn't be
              happier!. The platform seamlessly connected me with a like-minded
              partner who shares my vision. Together, we've overcome challenges
              and celebrated victories
            </p>
          </div>

          <div className="mb-[34px]">
            <img src={fivestars} alt="stars" />
          </div>
          <div className="flex items-center space-x-3">
            <div className="flex">
              <img src={kemi} alt="ellipse 20" />
            </div>
            <span className="changa-normal font-medium text-lg leading-[33px]">
              - Kemi Adeyemi
            </span>
          </div>
        </div>
      </div> */}
      <iframe id='testimonialto-carousel-helpai-tag-all-light' src="https://embed-v2.testimonial.to/carousel/all/helpai?theme=light&autoplay=off&showmore=off&one-row=on&hideDate=on&same-height=on&tag=all&arrowColor=9BA9B4" frameborder="0" scrolling="no" height="390px" width="100%"></iframe>
    </div>
  );
};

export default Testimonials;
