import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';

import googlelogo from '../../../assets/images/Google logo.svg';
import email from '../../../assets/images/outline-email.svg';
import closeSvg from '../../../assets/images/Vector Close.svg';
import mail from '../../../assets/images/verify-mail.svg';

export const ResponseModal = ({ isOpen, message, onClose }) => {
	const handleOpenGmail = () => {
		window.open('https://mail.google.com', '_blank');
	};

	return (
		<AnimatePresence>
			{isOpen && (
				<motion.div
					initial={{ opacity: 0, y: '0' }}
					animate={{ opacity: 1, y: '0' }}
					exit={{ opacity: 0, y: '0' }}
					transition={{ duration: 0.3 }}
					className='fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-30'>
					<div className='bg-white p-8 rounded-lg'>
						<div className='py-5 bg-white rounded-lg relative sm:px-20'>
							<button
								className='absolute top-5 right-7'
								onClick={() => onClose()}>
								<img
									src={closeSvg}
									alt='Close Icon'
								/>
							</button>
							<div>
								<div className='text-center flex justify-center'>
									<div className='mb-1'>
										<img
											src={mail}
											alt=''
										/>
									</div>
								</div>
								<div className='mt-3.5'>
									<p className='w-full mx-auto text-xl leading-[33px] tracking-[.0.25em] text-center font-bold mb-3'>
										Verify your email address
									</p>
									<p className='text-lg font-normal text-center'>
										{message}.<br /> Check your email and verify your account
									</p>
								</div>
								<div className='flex justify-center'>
									<button className='inputbox w-[300px] py-3 mt-5 mb-[29px] text-lg text-white bg-[#414080]' onClick={handleOpenGmail}>
										<div>
											<p>Open email</p>
										</div>
									</button>
								</div>
							</div>
						</div>
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};
