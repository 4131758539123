import { Accordion } from 'flowbite-react';

export default function PrivacyAccordion() {
	return (
		<Accordion>
			<Accordion.Panel>
				<Accordion.Title>
					<h1 className='text-[#414080] text-2xl changa-bold'>Privacy Notice.</h1>
				</Accordion.Title>
				<Accordion.Content>
					<p className='mb-2'>
						At HelpAI, accessible from helpai.co, one of our main priorities is the privacy of our visitors.
						This Privacy Policy document contains types of information that is collected and recorded by HelpAI
						and how we use it.
					</p>
					<p className='mb-2'>
						If you have additional questions or require more information about our Privacy Policy, do not
						hesitate to contact us.
					</p>
					<p className='mb-2'>
						This Privacy Policy applies only to our online activities and is valid for visitors to our website
						with regards to the information that they shared and/or collect in HelpAI. This policy is not
						applicable to any information collected offline or via channels other than this website.
					</p>
				</Accordion.Content>
			</Accordion.Panel>

			<Accordion.Panel>
				<Accordion.Title>
					<h1 className='text-[#414080] text-2xl changa-bold'>Consent.</h1>
				</Accordion.Title>
				<Accordion.Content>
					<p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
				</Accordion.Content>
			</Accordion.Panel>

			<Accordion.Panel>
				<Accordion.Title>
					<h1 className='text-[#414080] text-2xl changa-bold'>Information we collect.</h1>
				</Accordion.Title>
				<Accordion.Content>
					<p className='mb-2'>
						The personal information that you are asked to provide, and the reasons why you are asked to provide
						it, will be made clear to you at the point we ask you to provide your personal information.
					</p>
					<p className='mb-2'>
						If you contact us directly, we may receive additional information about you such as your name, email
						address, phone number, the contents of the message and/or attachments you may send us, and any other
						information you may choose to provide.
					</p>
					<p className='mb-2'>
						When you register for an Account, we may ask for your contact information, including items such as
						name, company name, address, email address, and telephone number.
					</p>
				</Accordion.Content>
			</Accordion.Panel>

			<Accordion.Panel>
				<Accordion.Title>
					<h1 className='text-[#414080] text-2xl changa-bold'>How we use your Information.</h1>
				</Accordion.Title>
				<Accordion.Content>
					<p>We use the information we collect in various ways, including to:</p>
					<p>
						<ul className='ml-5'>
							<li className='list-disc'>Provide, operate, and maintain our website</li>
							<li className='list-disc'>Improve, personalize, and expand our website</li>
							<li className='list-disc'>Understand and analyze how you use our website</li>
							<li className='list-disc'>Develop new products, services, features, and functionality</li>
							<li className='list-disc'>
								Communicate with you, either directly or through one of our partners, including for customer
								service, to provide you with updates and other information relating to the website, and for
								marketing and promotional purposes
							</li>
							<li className='list-disc'>Send you emails</li>
							<li className='list-disc'>Find and prevent fraud</li>
						</ul>
					</p>
				</Accordion.Content>
			</Accordion.Panel>

			<Accordion.Panel>
				<Accordion.Title>
					<h1 className='text-[#414080] text-2xl changa-bold'>Log files.</h1>
				</Accordion.Title>
				<Accordion.Content>
					<p>
						HelpAI follows a standard procedure of using log files. These files log visitors when they visit
						websites. All hosting companies do this and a part of hosting services&apos; analytics. The
						information collected by log files include internet protocol (IP) addresses, browser type, Internet
						Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of
						clicks. These are not linked to any information that is personally identifiable. The purpose of the
						information is for analyzing trends, administering the site, tracking users&apos; movement on the
						website, and gathering demographic information.
					</p>
				</Accordion.Content>
			</Accordion.Panel>

			<Accordion.Panel>
				<Accordion.Title>
					<h1 className='text-[#414080] text-2xl changa-bold'>Cookies and Web Beacons.</h1>
				</Accordion.Title>
				<Accordion.Content>
					<p>
						Like any other website, HelpAI uses &quot;cookies&quot;. These cookies are used to store information
						including visitors&apos; preferences, and the pages on the website that the visitor accessed or
						visited. The information is used to optimize the users&apos; experience by customizing our web page
						content based on visitors&apos; browser type and/or other information.
					</p>
				</Accordion.Content>
			</Accordion.Panel>

			<Accordion.Panel>
				<Accordion.Title>
					<h1 className='text-[#414080] text-2xl changa-bold'>Our Advertising Partners</h1>
				</Accordion.Title>
				<Accordion.Content>
					<p className='mb-2'>
						Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed
						below. Each of our advertising partners has their own Privacy Policy for their policies on user
						data. For easier access, we hyperlinked to their Privacy Policies below.
					</p>
					<p className='mb-2'>
						<ul className='ml-5'>
							<li className='list-disc'>Google</li>
						</ul>
					</p>
					<p>
						<a
							className='text-blue-500'
							href='https://policies.google.com/technologies/ads'>
							https://policies.google.com/technologies/ads
						</a>
					</p>
				</Accordion.Content>
			</Accordion.Panel>

			<Accordion.Panel>
				<Accordion.Title>
					<h1 className='text-[#414080] text-2xl changa-bold'>Third Party Privay Policies</h1>
				</Accordion.Title>
				<Accordion.Content>
					<p>
						HelpAI&apos;s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising
						you to consult the respective Privacy Policies of these third-party ad servers for more detailed
						information. It may include their practices and instructions about how to opt-out of certain
						options. You can choose to disable cookies through your individual browser options. To know more
						detailed information about cookie management with specific web browsers, it can be found at the
						browsers&apos; respective websites.
					</p>
				</Accordion.Content>
			</Accordion.Panel>

			<Accordion.Panel>
				<Accordion.Title>
					<h1 className='text-[#414080] text-2xl changa-bold'>
						CCPA Privacy Rights (Do Not Sell My Personal Information).
					</h1>
				</Accordion.Title>
				<Accordion.Content>
					<p className='mb-2'>Under the CCPA, among other rights, California consumers have the right to:</p>
					<p className='mb-2'>
						Request that a business that collects a consumer&apos;s personal data disclose the categories and
						specific pieces of personal data that a business has collected about consumers.
					</p>
					<p className='mb-2'>
						Request that a business delete any personal data about the consumer that a business has collected.
					</p>
					<p className='mb-2'>
						Request that a business that sells a consumer&apos;s personal data, not sell the consumer&apos;s
						personal data.
					</p>
					<p className='mb-2'>
						If you make a request, we have one month to respond to you. If you would like to exercise any of
						these rights, please contact us.
					</p>
				</Accordion.Content>
			</Accordion.Panel>

			<Accordion.Panel>
				<Accordion.Title>
					<h1 className='text-[#414080] text-2xl changa-bold'>GDPR Data Protection Rights.</h1>
				</Accordion.Title>
				<Accordion.Content>
					<p className='mb-2'>
						We would like to make sure you are fully aware of all of your data protection rights. Every user is
						entitled to the following:
					</p>
					<p className='mb-2'>
						The right to access – You have the right to request copies of your personal data. We may charge you
						a small fee for this service.
					</p>
					<p className='mb-2'>
						The right to rectification – You have the right to request that we correct any information you
						believe is inaccurate. You also have the right to request that we complete the information you
						believe is incomplete.
					</p>
					<p className='mb-2'>
						The right to erasure – You have the right to request that we erase your personal data, under certain
						conditions.
					</p>
					<p className='mb-2'>
						The right to restrict processing – You have the right to request that we restrict the processing of
						your personal data, under certain conditions.
					</p>
					<p className='mb-2'>
						The right to object to processing – You have the right to object to our processing of your personal
						data, under certain conditions.
					</p>
					<p className='mb-2'>
						The right to data portability – You have the right to request that we transfer the data that we have
						collected to another organization, or directly to you, under certain conditions.
					</p>
					<p className='mb-2'>
						If you make a request, we have one month to respond to you. If you would like to exercise any of
						these rights, please contact us.
					</p>
				</Accordion.Content>
			</Accordion.Panel>

			<Accordion.Panel>
				<Accordion.Title>
					<h1 className='text-[#414080] text-2xl changa-bold'>Children&apos;s Information.</h1>
				</Accordion.Title>
				<Accordion.Content>
					<p className='mb-2'>
						Another part of our priority is adding protection for children while using the internet. We
						encourage parents and guardians to observe, participate in, and/or monitor and guide their online
						activity.
					</p>
					<p>
						HelpAI does not knowingly collect any Personal Identifiable Information from children under the age
						of 13. If you think that your child provided this kind of information on our website, we strongly
						encourage you to contact us immediately and we will do our best efforts to promptly remove such
						information from our records.
					</p>
				</Accordion.Content>
			</Accordion.Panel>

			<Accordion.Panel>
				<Accordion.Title>
					<h1 className='text-[#414080] text-2xl changa-bold'>Changes to this Privacy Policy.</h1>
				</Accordion.Title>
				<Accordion.Content>
					<p className='mb-2'>
						We may update our Privacy Policy from time to time. Thus, we advise you to review this page
						periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on
						this page. These changes are effective immediately, after they are posted on this page.
					</p>
					<p>Our Privacy Policy was created with the help of the Privacy Policy Generator.</p>
				</Accordion.Content>
			</Accordion.Panel>

			<Accordion.Panel>
				<Accordion.Title>
					<h1 className='text-[#414080] text-2xl changa-bold'>Contact Us</h1>
				</Accordion.Title>
				<Accordion.Content>
					<p>
						If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.
					</p>
				</Accordion.Content>
			</Accordion.Panel>
		</Accordion>
	);
}