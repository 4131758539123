import { useState } from 'react';

import Modal from '../GetStartedModal';

const Cta = () => {
	const [email, setEmail] = useState('');
	const [showModal, setShowModal] = useState(false);

	const handleGetStarted = (e) => {
		e.preventDefault();
		setShowModal(true);
	};

	const closeModal = () => {
		setShowModal(false);
	};

	return (
		<section className='pt-16 pb-20 px-8 bg-[#F5F5FF] md:pt-20 md:pb-24'>
			<div className='text-center mb-9'>
				<h2 className='changa-bold mb-[6px] text-[22px] leading-10 md:text-4xl lg:text-5xl lg:leading-[88px]'>
					Stop Waiting and Start Building!
				</h2>
				<p className='max-w-xs mx-auto sm:max-w-none'>
					Grow Your Business with the Right Partner - Sign Up Now!
				</p>
			</div>
			<div className='flex justify-center items-center'>
				<form className='flex flex-col space-y-4 lg:flex-row lg:space-y-0'>
					{/* Your existing code for the email input and "Get Started" button */}
					<input
						type='email'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						className='border rounded-md p-2 w-full h-[70px] pl-[36px] outline-none placeholder:text-[#373737] placeholder:font-normal leading-[33px] inputbox mr-[21px] lg:w-[427px] lg:h-[90px] lg:text-lg border-gray-300'
						placeholder='Enter your Email Address'
						autoComplete='email'
					/>
					<button
						onClick={handleGetStarted}
						className='w-full h-[65px] text-lg bg-[#414080] rounded-[8px] text-white lg:w-[184px] lg:h-[87px] cursor-pointer'>
						Get Started
					</button>
				</form>
			</div>
			{showModal && <Modal closeModal={closeModal} />}
		</section>
	);
};

export default Cta;
