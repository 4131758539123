import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import useUser from '../../../hooks/useUser.jsx';
import BASE_URL from '../../../services/index.js';
import Layout from '../layout.jsx';
import tick from '../../../assets/images/Vector Check.svg';
import wrong from '../../../assets/images/Vector Wrong.svg';
import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import successTick from '../../../assets/images/success-tick.svg';
import closeSvg from '../../../assets/images/Vector Close.svg';
import MobileHeader from '../components/MobileHeader.jsx';

const CancelSubscription = () => {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });
	const { user } = useUser();
	const [error, setError] = useState();
	const [successMessage, setSuccessMessage] = useState('');
	const [isCancelModalOpen, setisCancelModalOpen] = useState(false);
	const navigate = useNavigate();
	const currentDate = new Date(); // Get the current date
    const formattedCurrentDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;

	const handleCloseCancelModal = () => {
		setisCancelModalOpen(false);
	};

	const handleCancelSubscription = async () => {
		try {
			const response = await axios.post(
				BASE_URL + '/payment/cancel-subscription',
				{},
				{
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			const res = response.data;
			console.log(res);
			setSuccessMessage(res.message);
			setisCancelModalOpen(true);
		} catch (error) {
			console.error('Error deleting account:', error);
			setError(error?.response?.data?.Message);
			setTimeout(() => {
				setError('');
			}, 8000);
		}
	};

	return (
		<>
			{isTabletOrMobile ? (
				<div className='bg-[#FAFBFD] px-2 py-14 h-screen'>
				<MobileHeader />
					<div className='flex flex-col items-center'>
						<div className='mb-4'>
							<h1 className='text-center text-[#454488] text-xl md:text-3xl changa-bold'>
								Heads up about your subscription
							</h1>
						</div>
						<div className='mb-10 flex justify-center items-center'>
						<div>
							<p classsName='text-center text-[#55555] text-base'>
								If you cancel, you’ll change to free our plan on {formattedCurrentDate} Here’s what your experience will be like:
							</p>
						</div>
						</div>
						<div>
							<div className=' flex-col space-y-2 mb-16 text-lg md:flex'>
								<div className='flex items-center space-x-4'>
									<img
										src={tick}
										alt='Vector Check'
									/>
									<span className='text-[#1A1A1A]'>View 26 co-founders daily</span>
								</div>
								<div className='flex items-center space-x-4'>
									<img
										src={tick}
										alt='Vector Check'
									/>
									<span className='text-[#1A1A1A]'>Message your matches</span>
								</div>
								<div className='flex items-center space-x-4'>
									<img
										src={tick}
										alt='Vector Check'
									/>
									<span className='text-[#1A1A1A]'>2 rewinds daily</span>
								</div>
								<div className='flex items-center space-x-4'>
									<img
										src={tick}
										alt='Vector Check'
									/>
									<span className='text-[#1A1A1A]'>Unlimited dislike</span>
								</div>
								<div className='flex items-center space-x-4'>
									<img
										src={wrong}
										alt='Vector Check'
									/>
									<span className='text-[#878787]'>See who likes your profile</span>
								</div>
								<div className='flex items-center space-x-4'>
									<img
										src={wrong}
										alt='Vector Check'
									/>
									<span className='text-[#878787]'>View co-founder's full details</span>
								</div>
							</div>
						</div>
						<div>
							<div className='mt-6'>
								<p
									onClick={handleCancelSubscription}
									className='text-[#fff] font-bold text-lg cursor-pointer bg-[#DF5C5C] py-2 px-16 rounded-lg'>
									Cancel subscription
								</p>
							</div>
						</div>
						<div className='mt-6'>
							<p
								onClick={() => {
									navigate('/dashboard');
								}}
								className='text-[#55555] font-bold text-lg cursor-pointer bg-transparent border border-[#CFCFCF] py-2 px-10 rounded-lg'>
								Never Mind
							</p>
						</div>
					</div>
					{isCancelModalOpen && (
						<div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10'>
							<div className='bg-white p-6 rounded-lg shadow-lg relative'>
								<button
									className='absolute right-7 top-5'
									onClick={handleCloseCancelModal}>
									<img
										src={closeSvg}
										alt='Close Icon'
									/>
								</button>
								<div>
									<div className='mb-9 text-center'>
										<div className='flex justify-center mb-4'>
											<img
												className=''
												src={successTick}
												alt='Success Tick'
											/>
										</div>
										<h2 className='mb-1 text-[19px] leading-[35px] font-semibold'>{successMessage}</h2>
										<p className='max-w-[300px] mx-auto text-[#373737] text-base'>
											You'll continue to have access to premium features until{' '}
											<span className='changa-bold'>1/6/2024</span>{' '}
										</p>
									</div>

									<button
										className='inputbox w-[417px] py-3 flex justify-center items-center space-x-4 mb-[29px] text-lg text-white bg-[#414080]'
										onClick={() => navigate('/dashboard')}>
										Go to Dashboard
									</button>
								</div>
							</div>
						</div>
					)}

					<AnimatePresence>
						{error && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg z-10'>
								<p>{error}</p>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			) : (
				<Layout>
					<div className='px-9 py-20 w-full'>
						<div className='flex flex-col items-center'>
							<div className='mb-4'>
								<h1 className='text-center text-[#454488] text-xl md:text-3xl changa-bold'>
									Heads up about your subscription
								</h1>
							</div>
							<div className='mb-10'>
								<p classsName='text-center text-[#55555] text-base'>
									If you cancel, you’ll change to free our plan on <span className='changa-bold'>{formattedCurrentDate}.</span>
									<br /> Here’s what your experience will be like:
								</p>
							</div>
							<div>
								<div className=' flex-col space-y-2 mb-16 text-lg md:flex'>
									<div className='flex items-center space-x-4'>
										<img
											src={tick}
											alt='Vector Check'
										/>
										<span className='text-[#1A1A1A]'>View 26 co-founders daily</span>
									</div>
									<div className='flex items-center space-x-4'>
										<img
											src={tick}
											alt='Vector Check'
										/>
										<span className='text-[#1A1A1A]'>Message your matches</span>
									</div>
									<div className='flex items-center space-x-4'>
										<img
											src={tick}
											alt='Vector Check'
										/>
										<span className='text-[#1A1A1A]'>2 rewinds daily</span>
									</div>
									<div className='flex items-center space-x-4'>
										<img
											src={tick}
											alt='Vector Check'
										/>
										<span className='text-[#1A1A1A]'>Unlimited dislike</span>
									</div>
									<div className='flex items-center space-x-4'>
										<img
											src={wrong}
											alt='Vector Check'
										/>
										<span className='text-[#878787]'>See who likes your profile</span>
									</div>
									<div className='flex items-center space-x-4'>
										<img
											src={wrong}
											alt='Vector Check'
										/>
										<span className='text-[#878787]'>View co-founder's full details</span>
									</div>
								</div>
							</div>
							<div>
								<div className='mt-6'>
									<p
										onClick={handleCancelSubscription}
										className='text-[#fff] font-bold text-lg cursor-pointer bg-[#DF5C5C] py-2 px-16 rounded-lg'>
										Cancel subscription
									</p>
								</div>
							</div>
							<div className='mt-6'>
								<p
									onClick={() => {
										navigate('/dashboard');
									}}
									className='text-[#55555] font-bold text-lg cursor-pointer bg-transparent border border-[#CFCFCF] py-2 px-10 rounded-lg'>
									Never Mind
								</p>
							</div>
						</div>
					</div>
					{isCancelModalOpen && (
						<div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10'>
							<div className='bg-white p-6 rounded-lg shadow-lg relative'>
								<button
									className='absolute right-7 top-5'
									onClick={handleCloseCancelModal}>
									<img
										src={closeSvg}
										alt='Close Icon'
									/>
								</button>
								<div>
									<div className='mb-9 text-center'>
										<div className='flex justify-center mb-4'>
											<img
												className=''
												src={successTick}
												alt='Success Tick'
											/>
										</div>
										<h2 className='mb-1 text-[19px] leading-[35px] font-semibold'>{successMessage}</h2>
										<p className='max-w-[300px] mx-auto text-[#373737] text-base'>
											You'll continue to have access to premium features until{' '}
											<span className='changa-bold'>1/6/2024</span>{' '}
										</p>
									</div>

									<button
										className='inputbox w-[417px] py-3 flex justify-center items-center space-x-4 mb-[29px] text-lg text-white bg-[#414080]'
										onClick={() => navigate('/dashboard')}>
										Go to Dashboard
									</button>
								</div>
							</div>
						</div>
					)}

					<AnimatePresence>
						{error && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg z-10'>
								<p>{error}</p>
							</motion.div>
						)}
					</AnimatePresence>
				</Layout>
			)}
		</>
	);
};

export default CancelSubscription;
